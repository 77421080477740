import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'

import TableCell from 'components/Table/TableCell'
import {
  GenderValue,
  HandReportValue,
  toGenderString,
  toReportHandString,
} from 'constants/CommonConstant'
import {useTranslation} from 'react-i18next'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import React from 'react'
import {Customer} from '../../../api/analysisApi'
import {
  dateToDashString,
  dateToDateAndTimeString,
  fromIsoDateOrStringToLocalDate,
  fromLocalDateOrStringToUtcDate,
} from '../../../helpers/dateHelper'

interface SummaryChildInfo extends Customer {
  analysisNo?: number
  ecAnalysisNo?: number
  eoAnalysisNo?: number
  analysisTime?: string
  requestId?: string
  eoRequestId?: string
}

export default function CustomerInfoChild(props: SummaryChildInfo) {
  const {
    treatmentAge,
    clientAge,
    clientGender,
    clientHand,
    clientName,
    clientFirstName,
    clientLastName,
    clientBirth,
    eceo,
    analysisNo,
    ecAnalysisNo,
    eoAnalysisNo,
    analysisTime,
    requestId,
    eoRequestId,
  } = props
  const {t} = useTranslation()
  const {i18n} = useTranslation()

  let eceoLabel = ''

  if (requestId != null && eoRequestId != null) {
    eceoLabel = t('IECEO')
  } else if (requestId != null && eoRequestId == null) {
    eceoLabel = `${t('IEC')}`
  } else if (requestId == null && eoRequestId != null) {
    eceoLabel = `${t('IEO')}`
  }

  return (
    <TableContainer>
      <Table style={{borderTop: '1px solid black'}} size='small'>
        <TableHead>
          <TableRow>
            {analysisNo && (
              <TableCell align='center'>{t('IAnalysisNo')}</TableCell>
            )}

            <TableCell align='center'>{t('IName')}</TableCell>
            <TableCell align='center'>{t('IGender')}</TableCell>
            <TableCell align='center'>
              {t('IBirthday')}({t('IAge')})
            </TableCell>
            <TableCell align='center'>{t('ISummaryTreatDate')}</TableCell>
            <TableCell align='center'>{t('IHandedness')}</TableCell>
            {eceoLabel !== '' && (
              <TableCell align='center'>{t('IECEO')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {analysisNo && (
              <TableCell align='center'>{analysisNo ?? '-'}</TableCell>
            )}
            {i18n.language === 'ko' && (
              <TableCell align='center'>
                {clientLastName || clientFirstName
                  ? `${clientLastName} ${clientFirstName}`
                  : 'No Name'}
              </TableCell>
            )}
            {i18n.language === 'en' && (
              <TableCell align='center'>
                {clientLastName || clientFirstName
                  ? `${clientFirstName} ${clientLastName}`
                  : 'No Name'}
              </TableCell>
            )}
            {i18n.language === 'ja' && (
              <TableCell align='center'>
                {clientLastName || clientFirstName
                  ? `${clientLastName} ${clientFirstName}`
                  : 'No Name'}
              </TableCell>
            )}
            <TableCell align='center'>
              {t(toGenderString(clientGender as GenderValue) ?? 'U')}
            </TableCell>
            <TableCell align='center'>
              {clientBirth ?? '1970-01-01'}(
              {treatmentAge ? Math.floor(treatmentAge) : t('INoAgeInfo')})
            </TableCell>
            <TableCell align='center'>
              {dateToDashString(
                fromIsoDateOrStringToLocalDate(analysisTime ?? '1970-01-01'),
              )?.split(' ')[0] ?? '1970-01-01'}
            </TableCell>
            <TableCell align='center'>
              {t(
                toReportHandString(clientHand as HandReportValue) ?? 'UNKNOWN',
              )}
            </TableCell>
            {eceoLabel !== '' && (
              <TableCell align='center'>{eceoLabel}</TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
