import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'
import useLangEn from 'hooks/useLangEn'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

function TablePrintF8({agingRoiScore}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const isEn = useLangEn()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const headTable = [
    t('IFrontalLobeBrainMapping'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable2 = useMemo(
    () => [
      {
        data: [
          {
            header: [
              {
                key: 'F8.',
                content: 'Medial Orbitofrontal',
                note: t('IMediaFrontalF8'),
              },
            ],
            image: getHealthReportReportImage('brainF8.png'),
          },
          isEn
            ? [t('IActiveRewardF8')]
            : [t('IActiveRewardF8'), t('IResponsibleSelfF8')],
          [t('IInsensitivityRewardF8'), t('IDifficultyMakingF8')],
          [getNumber(agingRoiScore?.Medial_Orbitofrontal_LH)],
          [getNumber(agingRoiScore?.Medial_Orbitofrontal_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F9.',
                content: 'Lateral Orbitofrontal',
                note: t('ILateralF9'),
              },
            ],
            image: getHealthReportReportImage('brainF9.png'),
          },
          isEn
            ? [t('ISituationsF9')]
            : [t('ISituationsF9'), t('IResponsibleF9')],
          [t('IInsensitivityF9'), t('IControllingF9')],
          [getNumber(agingRoiScore?.Lateral_Orbitofrontal_LH)],
          [getNumber(agingRoiScore?.Lateral_Orbitofrontal_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F10.',
                content: 'Precentral',
                note: t('IAnteriorF10'),
              },
            ],
            image: getHealthReportReportImage('brainF10.png'),
          },
          [t('IRelatedF10')],
          [t('IDecreasedF10')],
          [getNumber(agingRoiScore?.Precentral_LH)],
          [getNumber(agingRoiScore?.Precentral_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F11.',
                content: 'Paracentral',
                note: t('IAtrialF11'),
              },
            ],
            image: getHealthReportReportImage('brainF11.png'),
          },
          [t('IResponsibleF11'), t('IPlanningF11')],
          [t('IProblemsF11'), t('IMovingF11')],
          [getNumber(agingRoiScore?.Paracentral_LH)],
          [getNumber(agingRoiScore?.Paracentral_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F12.',
                content: 'Rostral Anterior Cingulate',
                note: t('IAnteriorF12'),
              },
            ],
            image: getHealthReportReportImage('brainF12.png'),
          },
          isEn ? [t('IRegionsF12')] : [t('IRegionsF12'), t('IConnectedF12')],
          [t('IRegulatingF12'), t('IStressF12')],
          [getNumber(agingRoiScore?.Rostral_Anterior_Cingulate_LH)],
          [getNumber(agingRoiScore?.Rostral_Anterior_Cingulate_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F13.',
                content: 'Caudal Anterior Cingulate',
                note: t('IAnteriorF13'),
              },
            ],
            image: getHealthReportReportImage('brainF13.png'),
          },
          isEn
            ? [t('ICognitiveF13')]
            : [t('ICognitiveF13'), t('IResponsibleF13'), t('IConnectedF13')],
          [t('IDecreasedF13'), t('IRegulatingF13')],
          [getNumber(agingRoiScore?.Caudal_Anterior_Cingulate_LH)],
          [getNumber(agingRoiScore?.Caudal_Anterior_Cingulate_RH)],
        ],
        background: 'White',
      },
    ],
    [isEn],
  )

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable2} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintF8
