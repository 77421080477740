import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    Lobe: {
      '& > .Title': {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 5,
      },
      '& > .Short': {
        fontSize: 15,
        fontWeight: 'bold',
        marginBottom: 5,
        color: Colors.primary,
      },
      '& > .DescWithScore': {
        display: 'flex',
        gap: 20,
        '& > .Desc': {
          flex: 7,
        },
        '& > .Score': {
          flex: 3,
          display: 'flex',
          '& > .Values': {
            width: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontWeight: 'bold',

            '& > .CircleLabel': {
              display: 'flex',
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: Colors.primary,
              color: Colors.white,
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
          '& > .Image': {
            flex: 1,
            '& img': {
              width: '100%',
              height: 'auto',
            },
          },
        },
      },
    },
    LobeTable: {
      '& table': {
        width: '100%',
      },
      '& > table thead': {
        backgroundColor: Colors.report,
        color: Colors.white,
        fontWeight: 'bold',
        fontSize: 15,
      },
      '& tr th': {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
      },
      '& td': {
        borderBottom: `1px solid ${Colors.border}`,
        padding: '10px 10px 10px 12px',
      },
      '& .Feature': {
        '& .Wrapper': {
          marginBottom: 15,
        },
        '& .Title': {
          fontSize: 14,
          fontWeight: 'bold',
        },
        '& .Text': {
          fontSize: 12,
        },
      },
      '& .IconList': {
        '& .Icon': {
          maxWidth: 220,
          '& .Label': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 8,
            marginBottom: 6,
            '& .Short': {
              display: 'flex',
              width: 24,
              height: 24,
              borderRadius: '50%',
              backgroundColor: Colors.primary,
              color: Colors.white,
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 11,
            },
            '& .Name': {
              flex: 1,
              fontSize: 13,
            },
          },
          '& img': {width: '100%', height: 'auto', marginTop: 9},
        },
      },

      '& .Score': {
        fontWeight: 'bold',
        marginBottom: 12,
        textAlign: 'center',
      },
      '& .AlignMiddle': {
        verticalAlign: 'middle',
      },
    },
  })

const useStyle = makeStyles(styles)
export default useStyle
