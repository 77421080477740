import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import useLangEn from 'hooks/useLangEn'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import CheckIcon from './CheckIcon'

const useStyles = makeStyles(HealthCenterReportStyle)

export interface AIDementiaPredictionGuideProps {
  stepText: string
  isActive: boolean
  color: string
  title: string
  content: string[]
}
function hexToRgba(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
const AIDementiaPredictionGuide = ({
  stepText,
  isActive,
  color,
  title,
  content,
}: AIDementiaPredictionGuideProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const rgbaColor = hexToRgba(color, 0.05)
  const isEn = useLangEn()

  return (
    <div
      className={classes.AIDementiaPredictionGuide}
      style={{
        height: isEn ? '135px' : '113px',
        border: `1px solid ${isActive ? color : '#fff'}`,
        borderLeft: `8px solid ${color}`,
        backgroundColor: isActive ? rgbaColor : '#fff',
        gap: isEn ? '55px' : '40px',
      }}
    >
      <div
        className={classes.AIDementiaPredictionGuideTitle}
        style={{
          width: isEn ? '526px' : '562px',
        }}
      >
        <p
          className={classes.AIDementiaPredictionGuideTitleNum}
          style={{color}}
        >
          {stepText}
        </p>
        <div className={classes.AIDementiaPredictionGuideTitleText}>
          {title}
        </div>
      </div>
      <div
        className={classes.AIDementiaPredictionGuideDes}
        style={{
          width: isEn ? '754px' : '793px',
        }}
      >
        <ul>
          {content.map((content) => {
            return <li>{t(content)}</li>
          })}
        </ul>
      </div>
      <div className={classes.AIDementiaPredictionGuideCheck}>
        <CheckIcon color={isActive ? color : '#EDEDED'} />
      </div>
    </div>
  )
}

export default AIDementiaPredictionGuide
