import {createSlice} from '@reduxjs/toolkit'
import {getTimeIca, setTimeIca} from 'api/storageApi'
import {RootState} from 'store'

interface ReloadImgState {
  time: number | undefined
}

const initialState: ReloadImgState = {
  time: getTimeIca() ? parseInt(getTimeIca(), 10) : undefined,
}

const slice = createSlice({
  name: 'reloadImgSlice',
  initialState,
  reducers: {
    setTime: (state) => {
      const time = Date.now()
      state.time = time
      setTimeIca(time.toString())
    },
  },
})

export default slice.reducer

export const {setTime} = slice.actions

export const selectTime = (state: RootState) => state.reloadImg.time
