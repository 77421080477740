import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import AppLoading from 'components/atoms/Loading'
import useStyles from 'components/Table/useTableStyles'
import {useRecentReadDialog} from 'features/therapy/useTherapyDialog'
import {useTranslation} from 'react-i18next'
import useAuth from 'features/auth/useAuth'
import useTherapySelect from 'features/therapy/useTherapySelect'
import {makeTimeText} from 'components/Dialog/Therapy/Read'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import {getOrganizationType} from 'api/storageApi'
import {ClientName} from 'components/atoms/ClientName'
import useTransparentGradientStyles from '../Style'
import {MultiTherapyHistory} from '../../../../../@types'

interface HeadCell {
  id: keyof TherapySelect
  label: string
}
const headCells: HeadCell[] = [
  {id: 'therapyId', label: 'ItherapyId_no'},
  {id: 'careDate', label: 'IRecentCare'},
  {id: 'therapyTime', label: 'IRecommendTime'},
  {id: 'frequency', label: 'IFrequency'},
  {id: 'careDoctor', label: 'IManager'},
  {id: 'groupName', label: 'IPrescriptionHistoryNormal'},
  {id: 'reservationType', label: 'IPBMPrescriptionHistory'},
]

const headCellsNormal: HeadCell[] = [
  {id: 'therapyId', label: 'ItherapyId_no'},
  {id: 'careDate', label: 'IRecentCare'},
  {id: 'therapyTime', label: 'IRecommendTime'},
  {id: 'frequency', label: 'IFrequency'},
  {id: 'careDoctor', label: 'IManager'},
  {id: 'groupName', label: 'IPrescriptionHistoryNormal'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props
  const organizationType = getOrganizationType()

  return (
    <TableHead>
      <TableRow className={classes.tableHeader} role='radio' tabIndex={-1}>
        {(organizationType === 'HEALTH' ? headCells : headCellsNormal).map(
          (headCell) => (
            <TableCell key={headCell.id} align='center' padding='none'>
              {t(headCell.label)}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  )
}

interface TherapyTableProps {
  therapyId: string
  isTherapySelected: boolean
}
export default function TherapyCustomerSelectTable(props: TherapyTableProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const gradientClasses = useTransparentGradientStyles()
  const {therapyId, isTherapySelected} = props
  const {user: currentUser} = useAuth()
  const rowsPerPage = 5
  const params = new URLSearchParams(location.search)
  const queryTherapyId = params.get('therapyId')
  const [loading, setLoading] = useState<boolean>(false)
  // CYM : 테라피 처방예약 버튼 클릭시 Modal 오픈
  const {onOpen: onTherapyRecentReadDialogOpen} = useRecentReadDialog()
  const {
    therapySelect: therapyList,
    loading: therapyLoading,
    query: queryState,
    paging: pagingState,
    pagingInfo,
    onSelect,
  } = useTherapySelect()

  const emptyRows = rowsPerPage - therapyList.length

  const setPageIndex = (page: number) =>
    onSelect({
      ...queryState,
      paging: {page, size: queryState.paging.size},
      uid: currentUser?.uid ?? '',
      uuid: therapyId ?? '',
    })

  const handleRecentClick = (data: MultiTherapyHistory) => {
    onTherapyRecentReadDialogOpen(data)
  }

  const getNormalGroupName = (value: MultiTherapyHistory) =>
    value.reservationType === 'HEALTH' ? '-' : value.groupName ?? '-'

  const getHealthGroupName = (value: MultiTherapyHistory) =>
    value.reservationType === 'HEALTH' && value.groupName
      ? value.groupName
      : '-'

  useEffect(() => {
    if (isTherapySelected) {
      const {uuid, paging: defaultPaging} = queryState
      let paging = {...defaultPaging}

      // UUID가 바뀌면 첫 페이지 부터 다시 노출
      if (therapyId !== uuid) paging = {...defaultPaging, page: 0}

      onSelect({
        ...queryState,
        paging,
        uid: currentUser?.uid ?? '',
        uuid: therapyId ?? '',
      })
    }
  }, [therapyId])

  useEffect(() => {
    const selectedTherapy = therapyList.find(
      (item) => queryTherapyId && item.therapyId === Number(queryTherapyId),
    )
    if (selectedTherapy) {
      setLoading(false)
      onTherapyRecentReadDialogOpen(selectedTherapy)
    } else if (queryTherapyId && pagingState.page < pagingInfo.totalPages) {
      setLoading(true)
      setPageIndex(pagingState.page + 1)
    } else {
      setLoading(false)
    }
  }, [therapyList, queryTherapyId])

  return (
    <>
      {loading && <AppLoading loading />}
      <TableContainer className={gradientClasses.transparentGradient}>
        {isTherapySelected ? (
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby='tableTitle'
              aria-label='enhanced table'
              size='small'
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {therapyList.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      onClick={() => {
                        const data = {...therapyList[index]}
                        handleRecentClick(data)
                      }}
                    >
                      <TableCell align='center'>{row.therapyId}</TableCell>
                      <TableCell align='center'>
                        {isoStringToDateAndTimeString(row.careDate)}
                      </TableCell>
                      <TableCell align='center'>
                        {makeTimeText(row.dataList[0].settingTime, t)}
                      </TableCell>
                      <TableCell align='center'>
                        {row.dataList[0].frequency}Hz
                      </TableCell>
                      <TableCell align='center'>
                        <ClientName
                          firstName={row.firstName}
                          lastName={row.lastName}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {getNormalGroupName(row)}
                      </TableCell>
                      {getOrganizationType() === 'HEALTH' && (
                        <TableCell align='center'>
                          {getHealthGroupName(row)}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
                  >
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              totalPageCount={pagingInfo.totalPages}
              currentPageIndex={pagingState.page}
              itemCountPerPage={pagingState.size}
              setCurrentPageIndex={setPageIndex}
              loading={therapyLoading}
              onItemCountPerPageChanged={(event) => {
                onSelect({
                  ...queryState,
                  paging: {
                    page: 0,
                    size: parseInt(event.target.value as string, 10),
                  },
                  uid: currentUser?.uid ?? '',
                  uuid: therapyId ?? '',
                })
              }}
            />
          </TableContainer>
        ) : (
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby='tableTitle'
              aria-label='enhanced table'
              size='small'
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                <TableRow
                  style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TableContainer>
    </>
  )
}
