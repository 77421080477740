import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'
import useLangEn from 'hooks/useLangEn'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  Frontal_LH: number
  Temporal_LH: number
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>
        {score}
        {t('IPoints')}
      </div>
      <Label score={score} />
    </div>
  )
}

function TablePrintF1({
  Frontal_LH,
  Temporal_LH,
  agingRoiScore,
}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const isEn = useLangEn()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const headTable = [
    t('IFrontalLobeBrainMapping'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable = useMemo(
    () => [
      {
        data: [
          {
            header: [
              {
                key: 'F1.',
                content: 'Frontal Pole',
                note: t('INoteFrontalPole'),
              },
            ],
            image: getHealthReportReportImage('brainF1.png'),
          },

          isEn
            ? [t('IPlanningForAction')]
            : [
                t('IPlanningForAction'),
                t('IEvaluationOfOwn'),
                t('IAbilityToMaintain'),
              ],
          [t('IDifficultiesInPlanning'), t('ITendencyToRepeat')],
          [getNumber(agingRoiScore?.Frontal_Pole_LH)],
          [getNumber(agingRoiScore?.Frontal_Pole_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F2.',
                content: 'Pars Opercularis',
                note: t('IParsNoteF2'),
              },
              {
                key: 'F3.',
                content: 'Pars Orbitalis',
                note: t('IParsNoteF3'),
              },
              {
                key: 'F4.',
                content: 'Pars Triangularis',
                note: t('IParsNoteF4'),
              },
            ],
            image: getHealthReportReportImage('brainF2.png'),
          },
          [t('IParsF3'), t('IParsF4'), t('IParsF2')],
          [t('IFunctionDeclines'), t('IDifficultToExpress')],
          [
            getNumber(agingRoiScore?.Pars_Opercularis_LH),
            getNumber(agingRoiScore?.Pars_Orbitalis_LH),
            getNumber(agingRoiScore?.Pars_Triangularis_LH),
          ],
          [
            getNumber(agingRoiScore?.Pars_Opercularis_RH),
            getNumber(agingRoiScore?.Pars_Orbitalis_RH),
            getNumber(agingRoiScore?.Pars_Triangularis_RH),
          ],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F5.',
                content: 'Rostral Middle Frontal',
                note: t('IRostralNoteF5'),
              },
            ],
            image: getHealthReportReportImage('brainF5.png'),
          },
          isEn
            ? [t('IResponsibleForWorking')]
            : [
                t('IResponsibleForWorking'),
                t('ILeftHemisphereResponsible'),
                t('IRightHemisphereResponsible'),
              ],
          isEn
            ? [t('IDecreasedConcentration'), t('IPoorWorking')]
            : [
                t('IDecreasedConcentration'),
                t('IPoorWorking'),
                t('ILeftSideFunctionDeclines'),
                t('IRightSideFunctionDeclines'),
              ],
          [getNumber(agingRoiScore?.Rostral_Middle_Frontal_LH)],
          [getNumber(agingRoiScore?.Rostral_Middle_Frontal_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F6.',
                content: 'Caudal Middle Frontal',
                note: t('ICaudalMiddle'),
              },
            ],
            image: getHealthReportReportImage('brainF6.png'),
          },
          isEn
            ? [t('IResponsibleForWorking')]
            : [
                t('IResponsibleForWorking'),
                t('ILeftHemisphereResponsible'),
                t('IRightHemisphereResponsible'),
              ],
          isEn
            ? [t('IDecreasedConcentration'), t('IPoorWorking')]
            : [
                t('IDecreasedConcentration'),
                t('IPoorWorking'),
                t('ILeftSideFunctionDeclines'),
                t('IRightSideFunctionDeclines'),
              ],
          [getNumber(agingRoiScore?.Caudal_Middle_Frontal_LH)],
          [getNumber(agingRoiScore?.Caudal_Middle_Frontal_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'F7.',
                content: 'Superior Frontal',
                note: t('IFrontalNoteF7'),
              },
            ],
            image: getHealthReportReportImage('brainF7.png'),
          },
          isEn
            ? [t('IResForHigher')]
            : [
                t('IResForHigher'),
                t('IRoleSimulating'),
                t('ILeftHemispherePlays'),
                t('IRightHemispherePlays'),
              ],
          isEn
            ? [t('ILeftSideFunction'), t('IRightSideFunction')]
            : [
                t('ILeftSideFunction'),
                t('IRightSideFunction'),
                t('IDifficultyPerforming'),
              ],
          [getNumber(agingRoiScore?.Superior_Frontal_LH)],
          [getNumber(agingRoiScore?.Superior_Frontal_RH)],
        ],
        background: 'White',
      },
    ],
    [isEn],
  )

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <WrapElement number={1} text={t('IDetailedAnalysisFrontal')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <span>{t('IFrontalLobeHeader')}</span>&nbsp;
                    <span className={classes.headerColorGreen}>
                      {t('IResOfHightOrder')}
                    </span>
                    &nbsp;
                    <span>{t('IMainArea')}</span>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IGovernsHigher')}</p>
                    <p>{t('IRepresentativeExample')}</p>
                    <p>{t('IDetailedAnalysis')}</p>
                    <p>{t('IHealthLevelOfEach')}</p>
                    <p>{t('ISuggestedLowerNumber')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>좌</div>
                        <Score score={getNumber(Frontal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>우</div>
                        <Score score={getNumber(Temporal_LH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img src={getHealthReportReportImage('brain.svg')} alt='' />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>
          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintF1
