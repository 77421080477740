import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import OverActive from 'pages/HealthCenter/Report/Elements/OverActive.svg'
import BalancedIcon from 'pages/HealthCenter/Report/Elements/BalancedIcon.svg'
import UnderactiveIcon from 'pages/HealthCenter/Report/Elements/UnderactiveIcon.svg'
import {AgingLobeScore} from 'api/healthCareApi'
import useLangEn from 'hooks/useLangEn'

const useStyles = makeStyles(HealthCenterReportStyle)

const scoreToString = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score
  if (Math.floor(parsedScore) < 30) {
    return 'IBrainAgingScore_underactive'
  }
  if (Math.floor(parsedScore) > 70) {
    return 'IBrainAgingScore_overactive'
  }

  return 'IBrainAgingScore_balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (Math.floor(parsedScore) < 30) {
    return UnderactiveIcon
  }
  if (Math.floor(parsedScore) > 70) {
    return OverActive
  }

  return BalancedIcon
}

const scoreToClass = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (Math.floor(parsedScore) < 30) {
    return 'Underactive'
  }
  if (Math.floor(parsedScore) > 70) {
    return 'OverActive'
  }

  return 'Balanced'
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const string = scoreToString(score)
  const className = scoreToClass(Number(score))
  return (
    <div className={`${classes.Label} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='sign-icon' />
    </div>
  )
}

const Score = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.scoreContainer}>
      <div className='Score'>
        {score}
        {t('IScore')}
      </div>
      <Label score={score} />
    </div>
  )
}

const RelatedSymptoms = ({arr}: {arr: string[]}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div className={classes.symptoms}>
      <p>{arr[0]}</p>
      <p>
        <span>{t('ILeftV2')} : </span>
        {arr[1]}
      </p>
      <p>
        <span>{t('IRightV2')} : </span>
        {arr[2]}
      </p>
    </div>
  )
}

const TableFiguresByPart = ({
  agingLobeScore,
}: {
  agingLobeScore: AgingLobeScore
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }
  const DATA = {
    head: [
      t('IBrainAgingScoreTable_area'),
      t('IBrainAgingScoreTable_featureP1'),
      t('IBrainAgingScoreTable_left'),
      t('IBrainAgingScoreTable_right'),
    ],
    body: [
      [
        t('IFrontalLobe'),
        [t('IFrontalLobeDes'), t('IFrontalLobeLeft'), t('IFrontalLobeRight')],
        getNumber(agingLobeScore?.Frontal_LH),
        getNumber(agingLobeScore?.Frontal_RH),
      ],
      [
        t('ITemporalLobe'),
        [
          t('ITemporalLobeDes'),
          t('ITemporalLobeLeft'),
          t('ITemporalLobeRight'),
        ],
        getNumber(agingLobeScore?.Temporal_LH),
        getNumber(agingLobeScore?.Temporal_RH),
      ],
      [
        t('IParietalLobe'),
        [
          t('IParietalLobeDes'),
          t('IParietalLobeLeft'),
          t('IParietalLobeRight'),
        ],
        getNumber(agingLobeScore?.Parietal_LH),
        getNumber(agingLobeScore?.Parietal_RH),
      ],
      [
        t('IOccipitalLobe'),
        [
          t('IOccipitalLobeDes'),
          t('IOccipitalLobeLeft'),
          t('IOccipitalLobeRight'),
        ],
        getNumber(agingLobeScore?.Occipital_LH),
        getNumber(agingLobeScore?.Occipital_RH),
      ],
    ],
  }
  return (
    <div className={classes.tableFiguresByPart}>
      <table>
        <thead>
          <tr>
            {DATA.head.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DATA.body.map((item, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {item.map((cell, cellIndex) => {
                  if (Array.isArray(cell)) {
                    return (
                      <td className={classes.secondCol}>
                        <RelatedSymptoms key={cellIndex} arr={cell} />
                      </td>
                    )
                  }
                  if (Number(cell)) {
                    return (
                      <td className={classes.scoreCol}>
                        <Score score={cell} />
                      </td>
                    )
                  }
                  return (
                    <td className={classes.firstCol} key={cellIndex}>
                      <p>{cell}</p>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export interface BrainFunctionBasicProps {
  agingThreeDimensionImage: string
  agingContourImage: string
  agingLobeScore: AgingLobeScore
  isPrint?: boolean
}

const BrainFunctionBasic = ({
  agingThreeDimensionImage,
  agingContourImage,
  agingLobeScore,
  isPrint,
}: BrainFunctionBasicProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const isEn = useLangEn()
  return (
    <div className={classes.brainBasic}>
      <WrapElement number={1} text={t('I3DBrainMapping')}>
        <div className={classes.groupImage}>
          <img
            className={classes.image}
            alt='pic1'
            src={agingThreeDimensionImage}
          />
          <img className={classes.image} alt='pic2' src={agingContourImage} />
        </div>
        <div className={classes.groupText}>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription')}
          </p>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription2')}
          </p>
        </div>
      </WrapElement>

      <WrapElement number={2} text={t('IFiguresByPart')}>
        <TableFiguresByPart agingLobeScore={agingLobeScore} />
        <div className={classes.groupText}>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription3')}
          </p>
        </div>
        <div
          className={`${classes.brainBasicDescription} ${
            isPrint && isEn ? classes.mtPrint : ''
          }`}
        >
          <div>
            <Label score={9} />
            <p>{t('I3DBrainMappingExplain1')}</p>
            <ul>
              <li>{t('I3DBrainMappingExplainDetail')}</li>
              <li>{t('I3DBrainMappingExplainDetail2')}</li>
            </ul>
          </div>
          <div>
            <Label score={99} />
            <p>{t('I3DBrainMappingExplain2')}</p>
            <ul>
              <li>{t('I3DBrainMappingExplainDetail3')}</li>
              <li>{t('I3DBrainMappingExplainDetail4')}</li>
            </ul>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default BrainFunctionBasic
