import React from 'react'
import {useTranslation} from 'react-i18next'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import {getPublicFile} from 'helpers/envHelper'

const getHealthReportReportImage = getPublicFile('images/health_center_report')

export type HeaderItem = {
  key: string
  content: string
  note: string
}

export type CellType =
  | {header: HeaderItem[]; image: string}
  | string[]
  | number
  | number[]

export interface DataTableProps {
  head: string[]
  body: {
    data: CellType[]
    background: string
  }[]
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Number(value)
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>
        {score}
        {t('IPoints')}
      </div>
      <Label score={score} />
    </div>
  )
}

const TableFiguresByPart = ({head, body}: DataTableProps) => {
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const DATA = {
    head,
    body,
  }

  const isObjectWithHeader = (
    cell: CellType,
  ): cell is {header: HeaderItem[]; image: string} => {
    return typeof cell === 'object' && cell !== null && 'header' in cell
  }

  return (
    <div className={classes.tableFiguresByPart}>
      <table>
        <thead>
          <tr>
            {DATA.head.map((item) => (
              <th>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DATA.body.map((row, rowIndex) => {
            return (
              <tr key={rowIndex} style={{background: `${row.background}`}}>
                {row.data.map((cell: CellType, cellIndex) => {
                  if (Array.isArray(cell) && cell.length > 0) {
                    if (typeof cell[0] === 'string')
                      return (
                        <td key={cellIndex} className={classes.secondCol}>
                          <ul>
                            {cell.map((itemChild, childIndex) => (
                              <li key={`${cellIndex}-${childIndex}`}>
                                {itemChild}
                              </li>
                            ))}
                          </ul>
                        </td>
                      )
                    return (
                      <td key={cellIndex} className={classes.scoreCol}>
                        <div className={classes.flexColScore}>
                          <div className={classes.widthMaxContent}>
                            {cell.map((itemChildScore) => (
                              <Score score={itemChildScore} />
                            ))}
                          </div>
                        </div>
                      </td>
                    )
                  }
                  if (isObjectWithHeader(cell)) {
                    return (
                      <td key={cellIndex} className={classes.firstCol}>
                        <div className={classes.firstColFlex}>
                          <div className={classes.width100}>
                            {cell.header.map((headerItem) => (
                              <>
                                <div className={classes.textRow1}>
                                  <div className={classes.keyRow1}>
                                    {headerItem.key}
                                  </div>
                                  &nbsp;
                                  <div className={classes.headerRow1}>
                                    <div className={classes.headerRow1Content}>
                                      {headerItem.content}
                                    </div>
                                    <div className={classes.headerRow1Note}>
                                      {headerItem.note}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                            <div className={classes.imgBrain}>
                              {cell.image && (
                                <img src={cell.image} alt='noImage' />
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    )
                  }
                  return (
                    <td key={cellIndex} className={classes.firstCol}>
                      <p>{cell}</p>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TableFiguresByPart
