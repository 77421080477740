import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'store'

type UserDetailDialogType = 'MY_INFO' | 'USER_INFO' | 'ADMIN_INFO'

interface OpenReadUserDetailAction {
  dialogType: UserDetailDialogType
  uid: string
}

type OpenEditUserDetailAction = OpenReadUserDetailAction

type OrgDetailDialogType = 'ORG_INFO' | 'ORG_ADMIN_INFO'

interface OpenReadOrgDetailAction {
  dialogType: OrgDetailDialogType
  uid: string
}

export interface ModalState {
  patientId: string
  userDetailUId: string
  userDialogType: UserDetailDialogType
  userAddDialogOpen: boolean
  userReadDialogOpen: boolean
  userEditDialogOpen: boolean
  commentAddDialogOpen: boolean
  department: Department | null
  departmentAddDialogOpen: boolean
  departmentEditDialogOpen: boolean
  orgDetailUId: string
  orgDetailOId: string
  orgDetailDialogType: string
  orgDetailReadInfoDialogOpen: boolean
  orgDetailEditInfoDialogOpen: boolean
  qnaReadDialogOpen: boolean
  qnaEditDialogOpen: boolean
  qnaCreateDialogOpen: boolean
  salesUserEditDialogOpen: boolean
  salesUserReadDialogOpen: boolean
  salesOrgReadDialogOpen: boolean
  salesOrgEditDialogOpen: boolean
  salesNoticeAddDialogOpen: boolean
  salseNoticeEditDialogOpen: boolean
  salseInspectionAddDialogOpen: boolean
  salseInspectionEditDialogOpen: boolean
  pointPaymentDialogOpen: boolean
  pointRankDialogOpen: boolean
  pointDetailDialogOpen: boolean
  pinLoginDialogOpen: boolean
  pinLoginUserId: string | null
  pinConfirmDialogOpen: boolean
  pinRegisterDialogOpen: boolean
  pinRegisterUserId: string | null
  findPasswordDialogOpen: boolean
  findIdDialogOpen: boolean
  receiptDialogOpen: boolean
  paymentDialogOpen: boolean
  firstLoginDialogOpen: boolean
  firstLoginUid?: string
  userDeletePasswordConfirmOpen: boolean
  packageSelectDialogOpen: boolean
  historyDialogOpen: boolean
  paymentInfoEditDialogOpen: boolean
  registeredCardDialogOpen: boolean
  packagePurchaseDialogOpen: boolean
  packagePaymentDialogOpen: boolean
  customerManagerDialogOpen: boolean
  confirmInvoiceSettingDialogOpen: boolean
}

const initialState: ModalState = {
  patientId: '',
  userDetailUId: '',
  userDialogType: 'MY_INFO',
  userAddDialogOpen: false,
  userReadDialogOpen: false,
  userEditDialogOpen: false,
  commentAddDialogOpen: false,
  department: null,
  departmentAddDialogOpen: false,
  departmentEditDialogOpen: false,
  orgDetailUId: '',
  orgDetailOId: '',
  orgDetailDialogType: 'MY_INFO',
  orgDetailReadInfoDialogOpen: false,
  orgDetailEditInfoDialogOpen: false,
  qnaReadDialogOpen: false,
  qnaEditDialogOpen: false,
  qnaCreateDialogOpen: false,
  salesUserEditDialogOpen: false,
  salesUserReadDialogOpen: false,
  salesOrgReadDialogOpen: false,
  salesOrgEditDialogOpen: false,
  salesNoticeAddDialogOpen: false,
  salseNoticeEditDialogOpen: false,
  salseInspectionAddDialogOpen: false,
  salseInspectionEditDialogOpen: false,
  pointPaymentDialogOpen: false,
  pointRankDialogOpen: false,
  pointDetailDialogOpen: false,
  pinLoginDialogOpen: false,
  pinLoginUserId: null,
  pinConfirmDialogOpen: false,
  pinRegisterDialogOpen: false,
  pinRegisterUserId: null,
  findPasswordDialogOpen: false,
  findIdDialogOpen: false,
  receiptDialogOpen: false,
  paymentDialogOpen: false,
  firstLoginDialogOpen: false,
  userDeletePasswordConfirmOpen: false,
  packageSelectDialogOpen: false,
  historyDialogOpen: false,
  paymentInfoEditDialogOpen: false,
  registeredCardDialogOpen: false,
  packagePurchaseDialogOpen: false,
  packagePaymentDialogOpen: false,
  customerManagerDialogOpen: false,
  confirmInvoiceSettingDialogOpen: false,
}

export const yes = createAction('dialog/ALERT/YES')
export const successYes = createAction('dialog/SUCCESS/YES')
export const failureYes = createAction('dialog/FAILURE/YES')
export const confirmYes = createAction('dialog/CONFIRM_YES')
export const confirmNo = createAction('dialog/CONFIRM_NO')

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openCommentAddDialog(state, action: PayloadAction<string>) {
      state.commentAddDialogOpen = true
      state.patientId = action.payload
    },
    closeCommentAddDialog(state) {
      state.commentAddDialogOpen = false
    },
    openUserAddDialog(state) {
      state.userAddDialogOpen = true
    },
    closeUserAddDialog(state) {
      state.userAddDialogOpen = false
    },
    openUserReadDialog(state, action: PayloadAction<OpenReadUserDetailAction>) {
      state.userDetailUId = action.payload.uid
      state.userDialogType = action.payload.dialogType
      state.userReadDialogOpen = true
    },
    closeUserReadDialog(state) {
      state.userReadDialogOpen = false
    },
    openUserEditDialog(state, action: PayloadAction<OpenEditUserDetailAction>) {
      state.userDetailUId = action.payload.uid
      state.userDialogType = action.payload.dialogType
      state.userEditDialogOpen = true
    },
    closeUserEditDialog(state) {
      state.userEditDialogOpen = false
    },
    openDepartmentAddDialog(state) {
      state.departmentAddDialogOpen = true
    },
    closeDepartmentAddDialog(state) {
      state.departmentAddDialogOpen = false
    },
    openDepartmentEditDialog(state, action: PayloadAction<Department>) {
      state.department = action.payload
      state.departmentEditDialogOpen = true
    },
    closeDepartmentEditDialog(state) {
      state.departmentEditDialogOpen = false
    },
    openOrgDetailInfoDialog(
      state,
      action: PayloadAction<OpenReadOrgDetailAction>,
    ) {
      state.orgDetailUId = action.payload.uid
      state.orgDetailDialogType = action.payload.dialogType
      state.orgDetailReadInfoDialogOpen = true
    },
    closeOrgDetailInfoDialog(state) {
      state.orgDetailReadInfoDialogOpen = false
    },
    openOrgDetailInfoEditDialog(state) {
      state.orgDetailEditInfoDialogOpen = true
    },
    closeOrgDetailInfoEditDialog(state) {
      state.orgDetailEditInfoDialogOpen = false
    },
    openQnaReadDialog(state) {
      state.qnaReadDialogOpen = true
    },
    closeQnaReadDialog(state) {
      state.qnaReadDialogOpen = false
    },
    openQnaEditDialog(state) {
      state.qnaEditDialogOpen = true
    },
    closeQnaEditDialog(state) {
      state.qnaEditDialogOpen = false
    },
    openQnaCreateDialog(state) {
      state.qnaCreateDialogOpen = true
    },
    closeQnaCreateDialog(state) {
      state.qnaCreateDialogOpen = false
    },
    openSalesOrgDetailInfoEditDialog(state) {
      state.orgDetailEditInfoDialogOpen = true
    },
    openSalesUserDetailInfoEditDialog(
      state,
      action: PayloadAction<OpenEditUserDetailAction>,
    ) {
      state.userDetailUId = action.payload.uid
      state.userDialogType = action.payload.dialogType

      state.salesUserEditDialogOpen = true
    },
    closeSalesUserDetailInfoEditDialog(state) {
      state.salesUserEditDialogOpen = false
    },
    openSalesUserReadDialog(
      state,
      action: PayloadAction<OpenReadUserDetailAction>,
    ) {
      state.userDetailUId = action.payload.uid
      state.userDialogType = action.payload.dialogType
      state.salesUserReadDialogOpen = true
    },
    closeSalesUserReadDialog(state) {
      state.salesUserReadDialogOpen = false
    },
    openSalesOrgReadDialog(state) {
      state.salesOrgReadDialogOpen = true
    },
    closeSalesOrgReadDialog(state) {
      state.salesOrgReadDialogOpen = false
    },
    openSalesOrgEditDialog(state) {
      state.salesOrgEditDialogOpen = true
    },
    closeSalesOrgEditDialog(state) {
      state.salesOrgEditDialogOpen = false
    },
    openSalesNoticeAddDialog(state) {
      state.salesNoticeAddDialogOpen = true
    },
    closeSalesNoticeAddDialog(state) {
      state.salesNoticeAddDialogOpen = false
    },
    openSalesNoticeEditDialog(state) {
      state.salseNoticeEditDialogOpen = true
    },
    closeSalesNoticeEditDialog(state) {
      state.salseNoticeEditDialogOpen = false
    },
    openSalesInspectionAddDialog(state) {
      state.salseInspectionAddDialogOpen = true
    },
    closeSalseInspectionAddDialog(state) {
      state.salseInspectionAddDialogOpen = false
    },
    openSalesInspectionEditDialog(state) {
      state.salseInspectionEditDialogOpen = true
    },
    closeSalesInspectionEditDialog(state) {
      state.salseInspectionEditDialogOpen = false
    },
    openPointPayment(state) {
      state.pointPaymentDialogOpen = true
    },
    closePointPayment(state) {
      state.pointPaymentDialogOpen = false
    },
    openPointRankDialog(state) {
      state.pointRankDialogOpen = true
    },
    closePointRankDialog(state) {
      state.pointRankDialogOpen = false
    },
    openPointDetailDialog(state) {
      state.pointDetailDialogOpen = true
    },
    closePointDetailDialog(state) {
      state.pointDetailDialogOpen = false
    },
    openPinLoginDialog(state, action: PayloadAction<string>) {
      state.pinLoginDialogOpen = true
      state.pinLoginUserId = action.payload
    },
    closePinLoginDialog(state) {
      state.pinLoginDialogOpen = false
      state.pinLoginUserId = null
    },
    openPinRegisterDialog(state, action: PayloadAction<string>) {
      state.pinRegisterDialogOpen = true
      state.pinRegisterUserId = action.payload
    },
    closePinRegisterDialog(state) {
      state.pinRegisterDialogOpen = false
      state.pinRegisterUserId = null
    },
    openPinConfirmDialog(state) {
      state.pinConfirmDialogOpen = true
    },
    closePinConfirmDialog(state) {
      state.pinConfirmDialogOpen = false
    },
    openFindPasswordDialog(state) {
      state.findPasswordDialogOpen = true
    },
    closeFindPasswordDialog(state) {
      state.findPasswordDialogOpen = false
    },
    openFindIdDialog(state) {
      state.findIdDialogOpen = true
    },
    closeFindIdDialog(state) {
      state.findIdDialogOpen = false
    },
    openReceiptDialog(state) {
      state.receiptDialogOpen = true
    },
    closeReceiptDialog(state) {
      state.receiptDialogOpen = false
    },
    openPaymentDialog(state) {
      state.paymentDialogOpen = true
    },
    closePaymentDialog(state) {
      state.paymentDialogOpen = false
    },
    openFirstLoginDialog(state, action: PayloadAction<string>) {
      state.firstLoginDialogOpen = true
      state.firstLoginUid = action.payload
    },
    openOrgCustomerManagementDialog(state) {
      state.customerManagerDialogOpen = true
    },
    closeCustomerManagementDialog(state) {
      state.customerManagerDialogOpen = false
    },
    closeFirstLoginDialog(state) {
      state.firstLoginDialogOpen = false
      state.firstLoginUid = undefined
    },
    openUserDeletePasswordConfirm(state) {
      state.userDeletePasswordConfirmOpen = true
    },
    closeUserDeletePasswordConfirm(state) {
      state.userDeletePasswordConfirmOpen = false
    },
    openPackageSelectDialog(state) {
      state.packageSelectDialogOpen = true
    },
    closePackageSelectDialog(state) {
      state.packageSelectDialogOpen = false
    },
    openHistoryDialog(state) {
      state.historyDialogOpen = true
    },
    closeHistoryDialog(state) {
      state.historyDialogOpen = false
    },
    openPaymentInfoEditDialog(state) {
      state.paymentInfoEditDialogOpen = true
    },
    closePaymentInfoEditDialog(state) {
      state.paymentInfoEditDialogOpen = false
    },
    openRegisteredCardDialog(state) {
      state.registeredCardDialogOpen = true
    },
    closeRegisteredCardDialog(state) {
      state.registeredCardDialogOpen = false
    },
    openPackagePurchaseDialog(state) {
      state.packagePurchaseDialogOpen = true
    },
    closePackagePurchaseDialog(state) {
      state.packagePurchaseDialogOpen = false
    },
    openPackagePaymentDialog(state) {
      state.packagePaymentDialogOpen = true
    },
    closePackagePaymentDialog(state) {
      state.packagePaymentDialogOpen = false
    },
    openConfirmOpenInvoiceSettingDialog(state) {
      state.confirmInvoiceSettingDialogOpen = true
    },
    closeConfirmOpenInvoiceSettingDialog(state) {
      state.confirmInvoiceSettingDialogOpen = false
    },
  },
})

export const {
  openCommentAddDialog,
  closeCommentAddDialog,
  openUserAddDialog,
  closeUserAddDialog,
  openUserReadDialog,
  closeUserReadDialog,
  openUserEditDialog,
  closeUserEditDialog,
  openDepartmentAddDialog,
  closeDepartmentAddDialog,
  openDepartmentEditDialog,
  closeDepartmentEditDialog,
  openOrgDetailInfoDialog,
  closeOrgDetailInfoDialog,
  openOrgDetailInfoEditDialog,
  closeOrgDetailInfoEditDialog,
  openQnaReadDialog,
  closeQnaReadDialog,
  openQnaEditDialog,
  closeQnaEditDialog,
  openQnaCreateDialog,
  closeQnaCreateDialog,
  openSalesOrgDetailInfoEditDialog,
  openSalesUserDetailInfoEditDialog,
  closeSalesUserDetailInfoEditDialog,
  openSalesUserReadDialog,
  closeSalesUserReadDialog,
  openSalesOrgReadDialog,
  closeSalesOrgReadDialog,
  openSalesOrgEditDialog,
  openSalesNoticeAddDialog,
  closeSalesNoticeAddDialog,
  closeSalesOrgEditDialog,
  openSalesNoticeEditDialog,
  closeSalesNoticeEditDialog,
  openSalesInspectionAddDialog,
  closeSalseInspectionAddDialog,
  openSalesInspectionEditDialog,
  closeSalesInspectionEditDialog,
  openPointPayment,
  closePointPayment,
  openPointRankDialog,
  closePointRankDialog,
  openPointDetailDialog,
  closePointDetailDialog,
  openPinLoginDialog,
  closePinLoginDialog,
  openPinRegisterDialog,
  closePinRegisterDialog,
  openPinConfirmDialog,
  closePinConfirmDialog,
  openFindPasswordDialog,
  closeFindPasswordDialog,
  openFindIdDialog,
  closeFindIdDialog,
  openReceiptDialog,
  closeReceiptDialog,
  openPaymentDialog,
  closePaymentDialog,
  openFirstLoginDialog,
  closeFirstLoginDialog,
  openUserDeletePasswordConfirm,
  closeUserDeletePasswordConfirm,
  openPackageSelectDialog,
  closePackageSelectDialog,
  openHistoryDialog,
  closeHistoryDialog,
  openPaymentInfoEditDialog,
  closePaymentInfoEditDialog,
  openRegisteredCardDialog,
  closeRegisteredCardDialog,
  openPackagePurchaseDialog,
  closePackagePurchaseDialog,
  openPackagePaymentDialog,
  closePackagePaymentDialog,
  openOrgCustomerManagementDialog,
  closeCustomerManagementDialog,
  openConfirmOpenInvoiceSettingDialog,
  closeConfirmOpenInvoiceSettingDialog,
} = modalSlice.actions

export const selectPatientId = (state: RootState) => state.modal.patientId
export const selectCommentAddDialogOpen = (state: RootState) =>
  state.modal.commentAddDialogOpen
export const selectUserDialogUid = (state: RootState) =>
  state.modal.userDetailUId
export const selectUserAddDialogOpen = (state: RootState) =>
  state.modal.userAddDialogOpen
export const selectUserDialogType = (state: RootState) =>
  state.modal.userDialogType
export const selectUserReadDialogOpen = (state: RootState) =>
  state.modal.userReadDialogOpen
export const selectUserEditDialogOpen = (state: RootState) =>
  state.modal.userEditDialogOpen
export const selectDepartment = (state: RootState) => state.modal.department
export const selectDepartmentAddDialogOpen = (state: RootState) =>
  state.modal.departmentAddDialogOpen
export const selectDepartmentEditDialogOpen = (state: RootState) =>
  state.modal.departmentEditDialogOpen
export const selectOrgDetailUId = (state: RootState) => state.modal.orgDetailUId
export const selectOrgDetailDialogType = (state: RootState) =>
  state.modal.orgDetailDialogType
export const selectReadOrgDetailDialogOpen = (state: RootState) =>
  state.modal.orgDetailReadInfoDialogOpen
export const selectEditOrgDetailDialogOpen = (state: RootState) =>
  state.modal.orgDetailEditInfoDialogOpen
export const selectQnaReadDialogOpen = (state: RootState) =>
  state.modal.qnaReadDialogOpen
export const selectQnaEditDialogOpen = (state: RootState) =>
  state.modal.qnaEditDialogOpen
export const selectQnaCreateDialogOpen = (state: RootState) =>
  state.modal.qnaCreateDialogOpen
export const selectSalesUserEditDialogOpen = (state: RootState) =>
  state.modal.salesUserEditDialogOpen
export const selectSalesUserReadDialogOpen = (state: RootState) =>
  state.modal.salesUserReadDialogOpen
export const selectSalesOrgReadDialogOpen = (state: RootState) =>
  state.modal.salesOrgReadDialogOpen
export const selectSalesOrgEditDialogOpen = (state: RootState) =>
  state.modal.salesOrgEditDialogOpen
export const selectSalesNoticeAddDialogOpen = (state: RootState) =>
  state.modal.salesNoticeAddDialogOpen
export const selectSalesNoticeEditDialogOpen = (state: RootState) =>
  state.modal.salseNoticeEditDialogOpen
export const selectSalesInspectionAddDialogOpen = (state: RootState) =>
  state.modal.salseInspectionAddDialogOpen
export const selectSalesInspectionEditDialogOpen = (state: RootState) =>
  state.modal.salseInspectionEditDialogOpen
export const selectPointPaymentDialogOpen = (state: RootState) =>
  state.modal.pointPaymentDialogOpen
export const selectPointRankDialogOpen = (state: RootState) =>
  state.modal.pointRankDialogOpen
export const selectPointDetailDialogOpen = (state: RootState) =>
  state.modal.pointDetailDialogOpen
export const selectPinLoginDialog = (state: RootState) => ({
  opened: state.modal.pinLoginDialogOpen,
  uid: state.modal.pinLoginUserId,
})
export const selectPinConfirmDialogOpen = (state: RootState) =>
  state.modal.pinConfirmDialogOpen
export const selectPinRegisterDialog = (state: RootState) => ({
  opened: state.modal.pinRegisterDialogOpen,
  uid: state.modal.pinRegisterUserId,
})
export const selectFindPasswordDialogOpen = (state: RootState) =>
  state.modal.findPasswordDialogOpen
export const selectFindIdDialogOpen = (state: RootState) =>
  state.modal.findIdDialogOpen
export const selectReceiptDialogOpen = (state: RootState) =>
  state.modal.receiptDialogOpen
export const selectPaymentDialogOpen = (state: RootState) =>
  state.modal.paymentDialogOpen
export const selectFirstLoginDialog = (state: RootState) => ({
  open: state.modal.firstLoginDialogOpen,
  uid: state.modal.firstLoginUid,
})
export const selectUserDeletePasswordConfirmOpen = (state: RootState) =>
  state.modal.userDeletePasswordConfirmOpen
export const selectPackageSelectedOpen = (state: RootState) =>
  state.modal.packageSelectDialogOpen
export const selectHistoryOpen = (state: RootState) =>
  state.modal.historyDialogOpen
export const selectPaymentInfoEditOpen = (state: RootState) =>
  state.modal.paymentInfoEditDialogOpen
export const selectRegisteredCardOpen = (state: RootState) =>
  state.modal.registeredCardDialogOpen
export const selectPackagePurchaseOpen = (state: RootState) =>
  state.modal.packagePurchaseDialogOpen
export const selectPackagePaymentOpen = (state: RootState) =>
  state.modal.packagePaymentDialogOpen
export const selectCustomerManagerOpen = (state: RootState) =>
  state.modal.customerManagerDialogOpen
export const selectConfirmInvoiceSettingDialogOpen = (state: RootState) =>
  state.modal.confirmInvoiceSettingDialogOpen

export default modalSlice.reducer
