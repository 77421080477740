import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  AnalysisResultIndicator,
  AnalysisResultItem,
  TypeScreenGraph,
} from 'api/analysisApi'
import {Colors} from 'components/common/useBaseStyle'
import ReportHeader from 'components/Report/ReportHeader'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import styled from 'styled-components'
import RenderRawDataGraph from 'components/molcules/Report/RawData/RenderRawDataGraph'
import {getChannel} from 'components/molcules/GenerateReportPDF/EEGReport/helper'

interface RawDataCleanedProps {
  readonly items: AnalysisResultItem[]
  readonly screen: TypeScreenGraph
}

function RawDataCleaned({items, screen}: RawDataCleanedProps) {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [cleaned, badEpoch] = items

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChanged}
        indicatorColor='secondary'
        textColor='secondary'
      >
        {cleaned && <Tab label='Cleaned data' />}
        {badEpoch && <Tab label='Cleaned data with bad epoch' />}
      </Tabs>

      {cleaned && (
        <TabPanel value={tabIndex} index={0}>
          <RenderRawDataGraph data={cleaned} type='cleaned' screen={screen} />
        </TabPanel>
      )}
      {badEpoch && (
        <TabPanel value={tabIndex} index={cleaned ? 1 : 0}>
          <RenderRawDataGraph data={badEpoch} type='cleaned' screen={screen} />
        </TabPanel>
      )}
    </div>
  )
}

const StyledRawData = styled.div`
  & .MuiAccordion-root {
    border: 1px solid ${Colors.border};
  }

  & .Image {
    padding: 30px;
    & img {
      margin: 0 auto;
      vertical-align: middle;
    }
  }

  & img {
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
  }
`

interface RawDataProps {
  readonly indicator: AnalysisResultIndicator
  readonly items: AnalysisResultItem[]
  readonly screen: TypeScreenGraph
}

function RawData({indicator, items, screen}: RawDataProps) {
  const {t} = useTranslation()

  const webItems = useMemo(
    () => items.filter((item) => item.imgType === 'WEB'),
    [items],
  )

  const improveSignal = useMemo(() => getChannel(items, t), [items, t])

  if (webItems.length === 0) return null
  const [full, cleaned, badEpoch, reliability] = webItems
  const renderRawData = () => {
    return <RenderRawDataGraph data={full} type='raw' screen={screen} />
  }
  const renderCleanedData = () => {
    if (cleaned || badEpoch) {
      return <RawDataCleaned items={[cleaned, badEpoch]} screen={screen} />
    }
    return null
  }
  return (
    <StyledRawData>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      {renderRawData()}
      {renderCleanedData()}
      <div>{improveSignal.length ? <p>{improveSignal}</p> : ''}</div>
      {reliability && (
        <div className='Image'>
          <Accordion defaultExpanded={false} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              * Split-half & Test-retest reliability
            </AccordionSummary>
            <AccordionDetails>
              <img
                src={reliability.imgPath}
                alt='reliability about cleaned data'
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </StyledRawData>
  )
}

export default RawData
