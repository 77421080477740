import {Lobe, Roi} from 'api/analysisApi'
import useStyle from 'components/molcules/Report/RoiComponent/RoiComponentStyle'
import {getPublicFile} from 'helpers/envHelper'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

const getEegReportImage = getPublicFile('images/eeg_report')

type Score = [number | string, number | string]
type LobeTableIcon = {
  short: string
  name: string
  image?: string
}
type LobeTableItem = {
  icons: LobeTableIcon[]
  feature: string
  example: string
  symptom: string
  scores: Score[]
}
type LobeData = {
  name: string
  feature: string
  desc: string
  image: string
  score: Score
  lobeItems: LobeTableItem[]
}

const getFrontalLobeComponentProps = (roi: Roi, lobe: Lobe): LobeData => ({
  name: 'IFrontalLobe',
  feature: 'IFrontalLobeFeature',
  desc: 'IFrontalLobeFeatureDesc',
  image: getEegReportImage('frontal_lobe.jpeg'),
  score: [lobe.Frontal_LH, lobe.Frontal_RH],
  lobeItems: [
    {
      icons: [
        {short: 'F1', name: 'IRoiF1', image: getEegReportImage('f1.png')},
      ],
      feature: 'IRoiF1Feature',
      example: 'IRoiF1Example',
      symptom: 'IRoiF1Symptom',
      scores: [[roi.Frontal_Pole_LH, roi.Frontal_Pole_RH]],
    },
    {
      icons: [
        {short: 'F2', name: 'IRoiF2'},
        {short: 'F3', name: 'IRoiF3'},
        {short: 'F4', name: 'IRoiF4', image: getEegReportImage('f2_f4.png')},
      ],
      feature: 'IRoiF2F4Feature',
      example: 'IRoiF2F4Example',
      symptom: 'IRoiF2F4Symptom',
      scores: [
        [roi.Pars_Opercularis_LH, roi.Pars_Opercularis_RH],
        [roi.Pars_Orbitalis_LH, roi.Pars_Orbitalis_RH],
        [roi.Pars_Triangularis_LH, roi.Pars_Triangularis_RH],
      ],
    },
    {
      icons: [
        {short: 'F5', name: 'IRoiF5', image: getEegReportImage('f5.png')},
        {short: 'F6', name: 'IRoiF6', image: getEegReportImage('f6.png')},
      ],
      feature: 'IRoiF5F6Feature',
      example: 'IRoiF5F6Example',
      symptom: 'IRoiF5F6Symptom',
      scores: [
        [roi.Rostral_Middle_Frontal_LH, roi.Rostral_Middle_Frontal_RH],
        [roi.Caudal_Middle_Frontal_LH, roi.Caudal_Middle_Frontal_RH],
      ],
    },
    {
      icons: [
        {short: 'F7', name: 'IRoiF7', image: getEegReportImage('f7.png')},
      ],
      feature: 'IRoiF7Feature',
      example: 'IRoiF7Example',
      symptom: 'IRoiF7Symptom',
      scores: [[roi.Superior_Frontal_LH, roi.Superior_Frontal_RH]],
    },
    {
      icons: [
        {short: 'F8', name: 'IRoiF8', image: getEegReportImage('f8.png')},
      ],
      feature: 'IRoiF8Feature',
      example: 'IRoiF8Example',
      symptom: 'IRoiF8Symptom',
      scores: [[roi.Medial_Orbitofrontal_LH, roi.Medial_Orbitofrontal_RH]],
    },
    {
      icons: [
        {short: 'F9', name: 'IRoiF9', image: getEegReportImage('f9.png')},
      ],
      feature: 'IRoiF9Feature',
      example: 'IRoiF9Example',
      symptom: 'IRoiF9Symptom',
      scores: [[roi.Lateral_Orbitofrontal_LH, roi.Lateral_Orbitofrontal_RH]],
    },
    {
      icons: [
        {short: 'F10', name: 'IRoiF10', image: getEegReportImage('f10.png')},
      ],
      feature: 'IRoiF10Feature',
      example: 'IRoiF10Example',
      symptom: 'IRoiF10Symptom',
      scores: [[roi.Precentral_LH, roi.Precentral_RH]],
    },
    {
      icons: [
        {short: 'F11', name: 'IRoiF11', image: getEegReportImage('f11.png')},
      ],
      feature: 'IRoiF11Feature',
      example: 'IRoiF11Example',
      symptom: 'IRoiF11Symptom',
      scores: [[roi.Paracentral_LH, roi.Paracentral_RH]],
    },
    {
      icons: [
        {short: 'F12', name: 'IRoiF12', image: getEegReportImage('f12.png')},
      ],
      feature: 'IRoiF12Feature',
      example: 'IRoiF12Example',
      symptom: 'IRoiF12Symptom',
      scores: [
        [roi.Rostral_Anterior_Cingulate_LH, roi.Rostral_Anterior_Cingulate_RH],
      ],
    },
    {
      icons: [
        {short: 'F13', name: 'IRoiF13', image: getEegReportImage('f13.png')},
      ],
      feature: 'IRoiF13Feature',
      example: 'IRoiF13Example',
      symptom: 'IRoiF13Symptom',
      scores: [
        [roi.Caudal_Anterior_Cingulate_LH, roi.Caudal_Anterior_Cingulate_RH],
      ],
    },
  ],
})

const getTemporalLobeComponentProps = (roi: Roi, lobe: Lobe): LobeData => ({
  name: 'ITemporalLobe',
  feature: 'ITemporalLobeFeature',
  desc: 'ITemporalLobeFeatureDesc',
  image: getEegReportImage('temporal_lobe.jpeg'),
  score: [lobe.Temporal_LH, lobe.Temporal_RH],
  lobeItems: [
    {
      icons: [
        {short: 'T1', name: 'IRoiT1', image: getEegReportImage('t1.png')},
      ],
      feature: 'IRoiT1Feature',
      example: 'IRoiT1Example',
      symptom: 'IRoiT1Symptom',
      scores: [[roi.Temporal_Pole_LH, roi.Temporal_Pole_RH]],
    },
    {
      icons: [
        {short: 'T2', name: 'IRoiT2', image: getEegReportImage('t2.png')},
      ],
      feature: 'IRoiT2Feature',
      example: 'IRoiT2Example',
      symptom: 'IRoiT2Symptom',
      scores: [[roi.Superior_Temporal_LH, roi.Superior_Temporal_RH]],
    },
    {
      icons: [
        {short: 'T3', name: 'IRoiT3', image: getEegReportImage('t3.png')},
      ],
      feature: 'IRoiT3Feature',
      example: 'IRoiT3Example',
      symptom: 'IRoiT3Symptom',
      scores: [[roi.Middle_Temporal_LH, roi.Middle_Temporal_RH]],
    },
    {
      icons: [
        {short: 'T4', name: 'IRoiT4', image: getEegReportImage('t4.png')},
      ],
      feature: 'IRoiT4Feature',
      example: 'IRoiT4Example',
      symptom: 'IRoiT4Symptom',
      scores: [[roi.Inferior_Temporal_LH, roi.Inferior_Temporal_RH]],
    },
    {
      icons: [
        {short: 'T5', name: 'IRoiT5', image: getEegReportImage('t5.png')},
      ],
      feature: 'IRoiT5Feature',
      example: 'IRoiT5Example',
      symptom: 'IRoiT5Symptom',
      scores: [[roi.Transverse_Temporal_LH, roi.Transverse_Temporal_RH]],
    },
    {
      icons: [
        {short: 'T6', name: 'IRoiT6', image: getEegReportImage('t6.png')},
      ],
      feature: 'IRoiT6Feature',
      example: 'IRoiT6Example',
      symptom: 'IRoiT6Symptom',
      scores: [
        [
          roi.Banks_of_Superior_Temporal_Sulcus_LH,
          roi.Banks_of_Superior_Temporal_Sulcus_RH,
        ],
      ],
    },
    {
      icons: [
        {short: 'T7', name: 'IRoiT7', image: getEegReportImage('t7.png')},
      ],
      feature: 'IRoiT7Feature',
      example: 'IRoiT7Example',
      symptom: 'IRoiT7Symptom',
      scores: [[roi.Fusiform_LH, roi.Fusiform_RH]],
    },
    {
      icons: [
        {short: 'T8', name: 'IRoiT8', image: getEegReportImage('t8.png')},
      ],
      feature: 'IRoiT8Feature',
      example: 'IRoiT8Example',
      symptom: 'IRoiT8Symptom',
      scores: [[roi.Entorhinal_LH, roi.Entorhinal_RH]],
    },
    {
      icons: [
        {short: 'T9', name: 'IRoiT9', image: getEegReportImage('t9.png')},
      ],
      feature: 'IRoiT9Feature',
      example: 'IRoiT9Example',
      symptom: 'IRoiT9Symptom',
      scores: [[roi.Parahippocampal_LH, roi.Parahippocampal_RH]],
    },
    {
      icons: [
        {short: 'T10', name: 'IRoiT10', image: getEegReportImage('t10.png')},
      ],
      feature: 'IRoiT10Feature',
      example: 'IRoiT10Example',
      symptom: 'IRoiT10Symptom',
      scores: [[roi.Insula_LH, roi.Insula_RH]],
    },
  ],
})

const getParietalLobeComponentProps = (roi: Roi, lobe: Lobe): LobeData => ({
  name: 'IParietalLobe',
  feature: 'IParietalLobeFeature',
  desc: 'IParietalLobeFeatureDesc',
  image: getEegReportImage('parietal_lobe.jpeg'),
  score: [lobe.Parietal_LH, lobe.Parietal_RH],
  lobeItems: [
    {
      icons: [
        {short: 'P1', name: 'IRoiP1', image: getEegReportImage('p1.png')},
      ],
      feature: 'IRoiP1Feature',
      example: 'IRoiP1Example',
      symptom: 'IRoiP1Symptom',
      scores: [[roi.Postcentral_LH, roi.Postcentral_RH]],
    },
    {
      icons: [
        {short: 'P2', name: 'IRoiP2', image: getEegReportImage('p2.png')},
      ],
      feature: 'IRoiP2Feature',
      example: 'IRoiP2Example',
      symptom: 'IRoiP2Symptom',
      scores: [[roi.Superior_Parietal_LH, roi.Superior_Parietal_RH]],
    },
    {
      icons: [
        {short: 'P3', name: 'IRoiP3', image: getEegReportImage('p3.png')},
      ],
      feature: 'IRoiP3Feature',
      example: 'IRoiP3Example',
      symptom: 'IRoiP3Symptom',
      scores: [[roi.Inferior_Parietal_LH, roi.Inferior_Parietal_RH]],
    },
    {
      icons: [
        {short: 'P4', name: 'IRoiP4', image: getEegReportImage('p4.png')},
      ],
      feature: 'IRoiP4Feature',
      example: 'IRoiP4Example',
      symptom: 'IRoiP4Symptom',
      scores: [[roi.Supramarginal_LH, roi.Supramarginal_RH]],
    },
    {
      icons: [
        {short: 'P5', name: 'IRoiP5', image: getEegReportImage('p5.png')},
      ],
      feature: 'IRoiP5Feature',
      example: 'IRoiP5Example',
      symptom: 'IRoiP5Symptom',
      scores: [[roi.Precuneus_LH, roi.Precuneus_RH]],
    },
    {
      icons: [
        {short: 'P6', name: 'IRoiP6', image: getEegReportImage('p6.png')},
      ],
      feature: 'IRoiP6Feature',
      example: 'IRoiP6Example',
      symptom: 'IRoiP6Symptom',
      scores: [[roi.Posterior_Cingulate_LH, roi.Posterior_Cingulate_RH]],
    },
  ],
})

const getOccipitalLobeComponentProps = (roi: Roi, lobe: Lobe): LobeData => ({
  name: 'IOccipitalLobe',
  feature: 'IOccipitalLobeFeature',
  desc: 'IOccipitalLobeFeatureDesc',
  image: getEegReportImage('occipital_lobe.jpeg'),
  score: [lobe.Occipital_LH, lobe.Occipital_RH],
  lobeItems: [
    {
      icons: [
        {short: 'O1', name: 'IRoiO1', image: getEegReportImage('o1.png')},
      ],
      feature: 'IRoiO1Feature',
      example: 'IRoiO1Example',
      symptom: 'IRoiO1Symptom',
      scores: [[roi.Lateral_Occipital_LH, roi.Lateral_Occipital_RH]],
    },
    {
      icons: [
        {short: 'O2', name: 'IRoiO2', image: getEegReportImage('o2.png')},
      ],
      feature: 'IRoiO2Feature',
      example: 'IRoiO2Example',
      symptom: 'IRoiO2Symptom',
      scores: [[roi.Cuneus_LH, roi.Cuneus_RH]],
    },
    {
      icons: [
        {short: 'O3', name: 'IRoiO3', image: getEegReportImage('o3.png')},
      ],
      feature: 'IRoiO3Feature',
      example: 'IRoiO3Example',
      symptom: 'IRoiO3Symptom',
      scores: [[roi.Pericalcarine_LH, roi.Pericalcarine_RH]],
    },
    {
      icons: [
        {short: 'O4', name: 'IRoiO4', image: getEegReportImage('o4.png')},
      ],
      feature: 'IRoiO4Feature',
      example: 'IRoiO4Example',
      symptom: 'IRoiO4Symptom',
      scores: [[roi.Lingual_LH, roi.Lingual_RH]],
    },
  ],
})

function RoiTableFeature(props: {
  feature: string
  symptom: string
  example: string
}) {
  const {t} = useTranslation()
  const {feature, symptom, example} = props

  return (
    <td className='Feature'>
      <div className='Wrapper'>
        <div className='Title'>{t('IKeyFunction')}</div>
        <div className='Text'>{t(feature)}</div>
      </div>
      {/* Hide to same pdf */}
      {/* <div className='Wrapper'>
        <div className='Title'>{t('IExample')}</div>
        <div className='Text'>{t(example)}</div>
      </div> */}
      <div className='Wrapper'>
        <div className='Title'>{t('IDeclineSymptom')}</div>
        <div className='Text'>{t(symptom)}</div>
      </div>
    </td>
  )
}

function RoiTableIcon({items}: {items: LobeTableIcon[]}) {
  const {t} = useTranslation()
  return (
    <td className='IconList'>
      {items.map((d) => (
        <div className='Icon'>
          <div className='Label'>
            <span className='Short'>{d.short}</span>
            <span className='Name'>{t(d.name)}</span>
          </div>
          {d.image && <img src={d.image} alt='' />}
        </div>
      ))}
    </td>
  )
}

function RoiTableScore({scores}: {scores: Score[]}) {
  const left = scores.map((s) => s[0])
  const right = scores.map((s) => s[1])
  return (
    <React.Fragment>
      <td className='Score AlignMiddle'>
        {left.map((l) => (
          <div>{l}%ile</div>
        ))}
      </td>
      <td className='Score AlignMiddle'>
        {right.map((r) => (
          <div>{r}%ile</div>
        ))}
      </td>
    </React.Fragment>
  )
}

function RoiTableItem({
  icons,
  feature,
  example,
  symptom,
  scores,
}: LobeTableItem) {
  return (
    <tr>
      <RoiTableIcon items={icons} />
      <RoiTableFeature feature={feature} symptom={symptom} example={example} />
      <RoiTableScore scores={scores} />
    </tr>
  )
}

interface RoiTableProps {
  className: string
  name: string
  items: LobeTableItem[]
}

function RoiTable(props: RoiTableProps) {
  const {t} = useTranslation()
  const {name, items, className} = props
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th>{t(name)}</th>
            <th>{t('IBrainAgingScoreTable_feature')}</th>
            <th>{t('IBrainAgingScoreTable_left')}</th>
            <th>{t('IBrainAgingScoreTable_right')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => (
            <RoiTableItem key={idx} {...item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface RoiComponentProps {
  roi: Roi
  lobe: Lobe
}

function RoiComponent({roi, lobe}: RoiComponentProps) {
  const {t} = useTranslation()
  const classes = useStyle()
  const [data, setData] = useState<LobeData[]>([])

  useEffect(() => {
    setData([
      getFrontalLobeComponentProps(roi, lobe),
      getTemporalLobeComponentProps(roi, lobe),
      getParietalLobeComponentProps(roi, lobe),
      getOccipitalLobeComponentProps(roi, lobe),
    ])
  }, [roi, lobe])

  return (
    <div className={classes.root}>
      {data.map((d) => (
        <div key={d.name} className={classes.Lobe}>
          <div className='Title'>{t('ILobeTitle', {lobe: t(d.name)})}</div>
          <div
            className='Short'
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{__html: t(d.feature)}}
          />
          <div className='DescWithScore'>
            <div className='Desc'>{t(d.desc)}</div>
            <div className='Score'>
              <div className='Values'>
                <div className='CircleLabel'>{t('ILeftP1')}</div>
                <div>{`${d.score[0]}%ile`}</div>
                <div className='CircleLabel'>{t('IRightP1')}</div>
                <div>{`${d.score[1]}%ile`}</div>
              </div>
              <div className='Image'>
                <img src={d.image} alt='' />
              </div>
            </div>
          </div>
          <RoiTable
            className={classes.LobeTable}
            name={d.name}
            items={d.lobeItems}
          />
        </div>
      ))}
    </div>
  )
}

export default RoiComponent
