import React from 'react'
import down from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/down.svg'
import useGaugeStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/style'
import useLangEn from 'hooks/useLangEn'

interface Props {
  headerName: string
  text: string
  index: number
  getColorInRound: string
  getColorText: string
  getBorderlineDot: string
}
function DescriptionPosition({
  headerName,
  text,
  index,
  getColorInRound,
  getColorText,
  getBorderlineDot,
}: Props) {
  const classes = useGaugeStyles()
  const isEn = useLangEn()

  return (
    <>
      {index === 3 && (
        <div className={classes.textAlignCenter}>
          <div className={classes.descriptionPosition1}>
            <img className={classes.leftArrow} src={down} alt='icon' />
            <span className={classes.leftArrowLine} />
            <div
              className={classes.topStatus}
              style={{background: getColorInRound, fontSize: isEn ? 15 : 18}}
            >
              {text}
            </div>
            <span className={classes.rightArrowLine} />
            <img className={classes.rightArrow} src={down} alt='icon' />
          </div>
          <span
            className={classes.topScorePosition}
            style={{color: getColorText}}
          >
            {headerName}
          </span>
        </div>
      )}
      {index === 1 && (
        <div className={classes.scoreGroup}>
          <span
            className={classes.topScorePosition}
            style={{color: getColorText}}
          >
            {headerName}
          </span>
          <div
            className={classes.topStatus}
            style={{
              background: getColorInRound,
              marginLeft: '15px',
              fontSize: isEn ? 15 : 18,
            }}
          >
            {text}
          </div>
          <div
            className={classes.lineDot}
            style={{border: getBorderlineDot, borderRight: 'none'}}
          >
            <span className={classes.topDot} />
            <span className={classes.botDot} />
          </div>
        </div>
      )}
      {index === 2 && (
        <div className={classes.scoreGroupRight}>
          <div
            className={classes.lineDotRight}
            style={{border: getBorderlineDot, borderLeft: 'none'}}
          >
            <span className={classes.topDotRight} />
            <span className={classes.botDotRight} />
          </div>

          <div
            className={classes.topStatus}
            style={{
              background: getColorInRound,
              marginLeft: '-30px',
              marginRight: '15px',
              fontSize: isEn ? 15 : 18,
            }}
          >
            {text}
          </div>
          <span
            className={classes.topScorePosition}
            style={{color: getColorText}}
          >
            {headerName}
          </span>
        </div>
      )}
    </>
  )
}
export default DescriptionPosition
