import {makeStyles, Theme} from '@material-ui/core/styles'

const useHealthCenterStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  imageChart: {
    width: 500,
    '& img': {
      width: 500,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  imageDescription: {},
  list: {
    width: 606,
    border: '1px solid #e0e0e0',
    padding: '20px 20px 20px 40px',
    listStyle: 'disc',
    backgroundColor: '#FAFAFA',
    '& li': {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '26.64px',
      letterSpacing: '-0.04em',
      '&::marker': {
        color: '#444',
      },
    },
  },
  textWeight: {
    fontWeight: 700,
  },
  titleDivider: {
    backgroundColor: '#f1f1f1',
  },
  padding: {
    padding: theme.spacing(1),
  },
  colorBarNormal: {
    width: 421,
    height: 12,
    backgroundColor: '#e0e0e0',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
  },
  colorBarFillNormal: {
    height: 12,
    backgroundColor: '#ee3d3b',
    borderRadius: 20,
    transition: 'width 0.3s ease-in-out',
  },
  colorBarRed: {
    width: 421,
    height: 12,
    backgroundColor: '#e0e0e0',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
  },
  colorBarFillRed: {
    height: 12,
    backgroundColor: '#ee3d3b',
    borderRadius: 20,
    transition: 'width 0.3s ease-in-out',
  },
  colorBarGrey: {
    width: 421,
    height: 12,
    backgroundColor: '#e0e0e0',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
  },
  colorBarFillGrey: {
    height: 12,
    backgroundColor: '#b4b4b4',
    borderRadius: 20,
    transition: 'width 0.3s ease-in-out',
  },
  colorBar: {
    width: 920,
    height: 12,
    backgroundColor: '#e0e0e0',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
  },
  colorBarFill: {
    height: 12,
    borderRadius: 20,
    backgroundColor: '#b4b4b4',
    transition: 'width 0.3s ease-in-out',
  },
  contentBar: {
    width: 421,
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentBarNotification: {
    width: 920,
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentNotificationWidth: {
    width: 920,
  },
  round: {
    height: 88,
    width: 88,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 25,
  },
  roundRed: {
    height: 88,
    width: 88,
    borderRadius: '50%',
    backgroundColor: '#ee3d3b',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 25,
  },
  roundGrey: {
    height: 88,
    width: 88,
    borderRadius: '50%',
    backgroundColor: '#b4b4b4',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 25,
  },
  percentBar: {
    display: 'flex',
    borderBottom: '1px solid #d9d9d9',
    marginBottom: 18,
  },
  paddingLeft40: {
    marginLeft: 40,
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #d9d9d9',
  },
  headerNotification: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px 0 40px',
    width: 245,
    fontWeight: 700,
  },
  contentNotification: {
    alignItems: 'center',
    width: 960,
    padding: '16px 30px 16px 20px',
  },
  headerName: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.03em !important',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  percentNotification: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #d9d9d9',
    height: 175,
    paddingLeft: 40,
  },
  textChart: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  textThroughAI: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '120%',
    letterSpacing: '-0.48px !important',
    marginBottom: 6,
  },
  textList: {
    color: '#444',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '148%',
    letterSpacing: '-0.72px !important',
  },
  textResultsOfPersonal: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.03em',
  },
  notificationHeader: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '148%',
    letterSpacing: '-0.54px !important',
  },
  notificationChildContent: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '148%',
    letterSpacing: '-0.54px !important',
  },
  contentBarPerNotification: {
    paddingTop: 8,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.2px',
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      color: '#333',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
    },
  },
  contentBarPerNotificationV2: {
    paddingTop: 8,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.2px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      color: '#333',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
    },
  },
  contentBarPerNotificationV2Child: {
    width: '47%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  width920: {
    width: 920,
  },
  headerNote: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '-0.03em',
  },
  flexHeaderName: {
    display: 'flex',
    alignItems: 'center',
  },
  textRound: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '18px',
    letterSpacing: '-0.04em',
    textAlign: 'center',
  },
  percentBarHeaderName: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21.6px',
    marginBottom: 4,
    '& p': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21.6px',
    },
  },
  iconNotification: {
    width: 24,
    height: 24,
    top: '3.6px',
    left: '1.95px',
  },
  frequencyText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '-0.04em',
    textAlign: 'center',
  },
  marker1: {
    position: 'absolute',
    top: 0,
    width: 1,
    height: '100%',
    opacity: '50%',
    backgroundColor: '#FFFFFF',
  },
  marker2: {
    position: 'absolute',
    top: 0,
    width: 1,
    height: '100%',
    opacity: '50%',
    backgroundColor: '#EE3D3B',
  },
  arrowIcon: {
    top: -20,
    width: 908,
    height: 16,
  },
  arrowIconShort: {
    top: -20,
    width: 409,
    height: 16,
  },
  arrowNoIcon: {
    top: -20,
    width: 908,
    minHeight: 16,
  },
  downNoImg: {
    marginBottom: 4,
    opacity: 0,
  },
  downImg: {
    marginBottom: 4,
  },
  NotificationHeaderName: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '18px',
  },
  textRoundItem2: {
    paddingTop: 8,
  },
  textRoundItem3: {
    paddingTop: 8,
  },
  fiveMajorIndicators: {
    marginBottom: 68,
    width: 1280,
    padding: '40px 60px 80px 60px',
    background: '#fff',
    '@media print': {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  pullUpContent: {},
  percentBarV2: {
    width: '100%',
    height: 174,
    borderTop: '5px solid #7F8892',
    borderBottom: '1px solid#E0E0E0',
    display: 'flex',
    padding: '24px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '30px',
    alignSelf: 'stretch',
    background: 'rgba(127, 136, 146, 0.05)',
  },
  percentBarV2Top: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  topScoreIndex: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '5px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '32px',
    background: '#666',
    color: '#FFF',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%', // 12px
    letterSpacing: '-0.36px',
  },
  topScore: {
    display: 'flex',
    gap: 8,
  },
  topScoreDetail: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  topStatus: {
    display: 'flex',
    width: '52px',
    height: '52px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    background: '#B4B4B4',
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important', // 21.6px
  },
  topScorePosition: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important', // 21.6px
    letterSpacing: '-0.54px !important',
  },
  topScorePercent: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important',
  },
  topScorePercentScore: {
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important',
  },
  percentBarV2Bot: {
    position: 'relative',
    width: '100%',
  },
  percentBarV2Arrow: {
    position: 'absolute',
    top: -15,
  },
  botBar: {
    borderRadius: '14px',
    border: '1px solid #CCC',
    background: '#DDD',
    height: '12px',
    alignSelf: 'stretch',
  },
  botStatus: {
    position: 'absolute',
    display: 'flex',
  },
  marker3: {
    position: 'absolute',
    top: 0,
    height: '100%',
    '& span': {
      display: 'block',
      width: 1,
      opacity: '50%',
      backgroundColor: '#EE3D3B',
      height: '100%',
      marginBottom: 8,
    },
    '& p': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
      color: '#333',
      marginLeft: -15,
    },
    '& div': {
      height: 12,
      marginBottom: 8,
    },
  },
}))

export default useHealthCenterStyles
