import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch} from 'store'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import {Chip, OutlinedInput, TableHead, Typography} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from 'components/molcules/Pagination'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {openSalesUserReadDialog} from 'features/modal/modalSlice'
import useInvoiceUsersOrg from 'features/invoice/useInvoiceUsersOrg'
import useCustomerOrg from 'features/invoice/useInvoiceInquiry'
import {SearchAllResponse} from 'api/salesApi'
import {ADMIN_INFO_DIALOG_TYPE} from 'constants/DialogConstant'
import {getTextRole} from 'helpers/invoiceHelper'
import useStyles from 'components/Table/useTableStyles'

function TableToolbar() {
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchType, setSearchType] = useState<string>('username')
  const {orgSelected} = useCustomerOrg()

  const {onSearch} = useInvoiceUsersOrg()
  const TYPE_SEARCH_ORG = [
    {
      name: t('IName'),
      oid: 'username',
    },
    {
      name: t('ITel'),
      oid: 'tel',
    },
  ]

  const handleSearch = () => {
    if (!searchKeyword) {
      onSearch({
        orgId: orgSelected?.org?.id,
        page: 0,
        size: 10,
      })
      return
    }
    if (searchType === 'username') {
      onSearch({
        orgId: orgSelected?.org?.id,
        username: searchKeyword,
        page: 0,
        size: 10,
      })
    } else if (searchType === 'tel') {
      onSearch({
        orgId: orgSelected?.org?.id,
        tel: searchKeyword,
        page: 0,
        size: 10,
      })
    }
  }

  const handleChangeStatus = (value: UserSearchKind) => {
    setSearchType(value)
  }

  return (
    <div className={classes.root} style={{justifyContent: 'flex-end'}}>
      <div className={classes.searchOrgWrap}>
        <div style={{display: 'flex', gap: 2, justifyContent: 'space-between'}}>
          <Select
            value={searchType || TYPE_SEARCH_ORG[0]?.oid}
            displayEmpty
            className={classes.searchSelectType}
            onChange={(event) => {
              handleChangeStatus(event.target.value as UserSearchKind)
            }}
            input={<OutlinedInput classes={outlinedInputClasses} />}
          >
            {TYPE_SEARCH_ORG?.map((item) => (
              <MenuItem value={item.oid} key={item.oid}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.searchWrap}>
          <input
            placeholder={t('ISearch')}
            className={classes.searchInput}
            value={searchKeyword}
            onChange={(event) => {
              const value = event?.currentTarget?.value
              setSearchKeyword(value)
            }}
          />
          <span className={classes.searchDivider} />
          <button
            type='submit'
            onClick={handleSearch}
            color='primary'
            className={classes.searchButton}
          >
            {t('ISearch')}
          </button>
        </div>
      </div>
    </div>
  )
}

function RenderTableRow({item}: {item: SearchAllResponse}) {
  const {orgSelected} = useCustomerOrg()
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const {t} = useTranslation()
  return (
    <TableRow
      hover
      className={classes.hoverTable}
      onClick={() => {
        dispatch(
          openSalesUserReadDialog({
            dialogType: ADMIN_INFO_DIALOG_TYPE,
            uid: item.userUid.toString(),
          }),
        )
      }}
    >
      <TableCell align='center'>{orgSelected?.org?.name ?? '-'}</TableCell>
      <TableCell align='center'>
        {t('IGetFullName', {
          firstName: item.firstName ?? '',
          lastName: item.lastName ?? '',
        })}
      </TableCell>
      <TableCell align='center'>{item.email}</TableCell>
      <TableCell align='center'>{item.departmentName ?? '-'}</TableCell>
      <TableCell align='center'>{item.tel ?? '-'}</TableCell>
      <TableCell align='center'>{getTextRole(item.roleLevel) ?? '-'}</TableCell>
    </TableRow>
  )
}

function UsersOrgTable() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {items, onSearch, search, pagingInfo, paging, loading, query} =
    useInvoiceUsersOrg()
  const {orgSelected} = useCustomerOrg()

  React.useState<SearchedUser[]>()
  const noticeTableHead = [
    'IOrgName',
    'IName',
    'IEmail',
    'IDepartment',
    'ITel',
    'IAuthority',
  ]
  const header = useMemo(() => noticeTableHead, [])
  // const emptyRows = items === null ? 5 : 5 - items.length

  const setPageIndex = (page: number) => {
    onSearch({
      ...search,
      page,
      size: paging.size ?? 10,
    })
  }

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...search,
      page: 0,
      size,
    })
  }

  useEffect(() => {
    if (orgSelected?.org?.id)
      onSearch({
        ...search,
        orgId: orgSelected?.org?.id,
        page: 0,
        size: 10,
      })
  }, [orgSelected?.org?.id])

  return (
    <>
      <Typography className={classes.subTitle}>
        {t('IRegisteredUsers')}
      </Typography>
      <div className={classes.toolbar}>
        <Chip
          size='small'
          label={`Total ${orgSelected?.org?.id ? pagingInfo.totalElements : 0}`}
        />
        <TableToolbar />
      </div>
      <TableContainer>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {header.map((value) => (
                <TableCell align='center'>{t(`${value}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              orgSelected?.org?.id &&
              items.map((item) => (
                <RenderTableRow key={item.userId} item={item} />
              ))}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.number ?? 0}
          itemCountPerPage={query.paging.size ?? 10}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={onSizeChange}
        />
      </TableContainer>
    </>
  )
}

export default UsersOrgTable
