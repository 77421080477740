import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import {
  closeSalesOrgReadDialog,
  openSalesOrgEditDialog,
  selectSalesOrgReadDialogOpen,
} from 'features/modal/modalSlice'
import useSalesOrganization from 'features/org/useSalesOrganization'
import {isObjectEmpty} from 'helpers/commonHelper'
import {useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

interface SalesOrganizationReadProps {
  oid: string
}

export default function SalesOrganizationReadDialog({
  oid,
}: SalesOrganizationReadProps) {
  const classes = useStyles()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {i18n} = useTranslation()
  const {onFetch: readDataFetch, organization} = useSalesOrganization()
  const passwordMark = '****'

  const handleDialogClose = () => {
    dispatch(closeSalesOrgReadDialog())
  }
  const handleEditDialogOpen = () => {
    dispatch(closeSalesOrgReadDialog())
    dispatch(openSalesOrgEditDialog())
  }

  // 다이얼로그 상태값
  const orgDetailInfoDialogOpen = useAppSelector(selectSalesOrgReadDialogOpen)

  useEffect(() => {
    if (orgDetailInfoDialogOpen) {
      readDataFetch(oid)
    }
  }, [orgDetailInfoDialogOpen])

  return (
    <Dialog open={orgDetailInfoDialogOpen} fullWidth maxWidth='md'>
      <Card className={classes.cardWrap}>
        <div className={classes.root}>
          {/* 상단 타이틀 컨테이너  */}
          <div
            className={classes.longTitleContainer}
            style={{backgroundColor: '#f9f9fb'}}
          >
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleDialogClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography variant='h6' className={classes.title}>
                {t('IInstituteName')}
              </Typography>
            </div>
          </div>

          <div className={classes.scroll} style={{backgroundColor: '#f9f9fb'}}>
            <div className={classes.cardContent}>
              <Typography className={classes.padding} />
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IOrgName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.orgName ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ICeoName')}
                  </Typography>
                  {(i18n.language === 'ko' || i18n.language === 'ja') && (
                    <Typography className={classes.subTitle}>
                      {organization?.lastName ?? '-'}
                      {organization?.firstName ?? '-'}
                    </Typography>
                  )}
                  {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                    <Typography className={classes.subTitle}>
                      {organization?.firstName ?? '-'}
                      {organization?.lastName ?? '-'}
                    </Typography>
                  )}
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IEmail')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.email ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IPassword')}
                  </Typography>
                  <Typography
                    style={{flexWrap: 'nowrap', textAlign: 'left'}}
                    className={classes.subTitle}
                  >
                    {passwordMark}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ICountry')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {!isObjectEmpty(organization?.country) && (
                      <Typography className={classes.subTitle}>
                        {organization?.country.name}
                      </Typography>
                    )}
                    {isObjectEmpty(organization?.country) && (
                      <Typography className={classes.subTitle}>-</Typography>
                    )}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IAddress')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.address ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ITel')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.tel ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IIndustryDetail')}
                  </Typography>
                  <Typography>
                    {!isObjectEmpty(organization?.industry) && (
                      <Typography className={classes.subTitle}>
                        {organization?.industry.title ?? '-'}
                      </Typography>
                    )}
                    {isObjectEmpty(organization?.industry) && (
                      <Typography className={classes.subTitle}>-</Typography>
                    )}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IBusinessNo')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.businessNo ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IOrgNursingInstitutionNumber')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.institutionNo ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IJoinDate')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.createdAt ?? '-'}
                  </Typography>
                </div>
              </div>
              <Typography className={classes.padding} />
            </div>
            <Typography className={classes.padding} />
          </div>
          {/* 하단 버튼 컨테이너  */}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className={classes.buttonContainer} style={{width: 260}}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleDialogClose}
                className={classes.okButtonColor}
              >
                {t('IOk')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}
