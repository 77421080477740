import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import Analysis from 'pages/HealthCenter/Report/Elements/Analysis'
import {getPublicFile} from 'helpers/envHelper'
import PercentBarCardV4 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV4'

const useStyles = makeStyles(HealthCenterReportStyle)

export interface BrainFunctionalConnectivityProps {
  partTwoImg: string
  partThreeImg: string
  brainConnectivityBetweenLobesScore: {
    vertical: {
      left: number
      right: number
    }
    horizontal: number
  } | null
  brainConnectivityByLobeScore: {
    frontal: {
      left: number
      right: number
    }
    pariocci: {
      left: number
      right: number
    }
  } | null
}

const PagePrintWrap3 = ({
  partTwoImg,
  partThreeImg,
  brainConnectivityByLobeScore,
  brainConnectivityBetweenLobesScore,
}: BrainFunctionalConnectivityProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getHealthReportReportImage = getPublicFile(
    'images/health_center_report',
  )

  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }

  const getText = (value = 0, key: string) => {
    if (value < 30) {
      return t(`IBot${key}`, {
        value: getNumber(value),
      })
    }
    if (value > 70) {
      return t(`ITop${key}`, {
        value: getNumber(value),
      })
    }
    return t(`IMid${key}`, {
      value: getNumber(value),
    })
  }

  const textPart3Title1 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.left ?? 0,
      'LeftFrontTitle',
    )
  }, [brainConnectivityByLobeScore?.frontal?.left])
  const textPart3Text1 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.left ?? 0,
      'LeftFrontText',
    )
  }, [brainConnectivityByLobeScore?.frontal?.left])

  const textPart3Title2 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.right ?? 0,
      'RightFrontTitle',
    )
  }, [brainConnectivityByLobeScore?.frontal?.right])
  const textPart3Text2 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.right ?? 0,
      'RightFrontText',
    )
  }, [brainConnectivityByLobeScore?.frontal?.right])

  const textPart3Title3 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.left ?? 0,
      'LeftRearTitle',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.left])
  const textPart3Text3 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.left ?? 0,
      'LeftRearText',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.left])

  const textPart3Title4 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.right ?? 0,
      'RightRearTitle',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.right])
  const textPart3Text4 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.right ?? 0,
      'RightRearText',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.right])

  return (
    <div className={classes.brainBasic}>
      <WrapElement number={3} text={t('IBrainConnectivityBrainRegion')}>
        <div>
          <div className={classes.brainConnectivityBrainRegionTabs3}>
            <div
              className={`${classes.cardLeftContent} ${classes.cardLeftContentAdditionalV1}`}
            >
              <img
                className={classes.cardLeftImg}
                src={getHealthReportReportImage('fourDivisionsBase.webp')}
                alt='Contour'
                style={{width: '285px'}}
              />
            </div>
            <div className={classes.percentBarCar}>
              <PercentBarCardV4
                index={1}
                headerName={t('ILeftFront')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.frontal?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV4
                index={3}
                headerName={t('IRightFront')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.frontal?.right ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV4
                index={2}
                headerName={t('ILeftRear')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.pariocci?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />

              <PercentBarCardV4
                index={4}
                top={t('ILowness')}
                headerName={t('IRightRear')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.pariocci?.right ?? 0,
                )}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
            </div>
          </div>

          <div
            className={`${classes.cardRightContent} ${classes.cardRightContentAdditional}`}
          >
            <div>
              <p className={classes.textBold}>
                {t('IBrainConnectivityBrainRegionTitle')}
              </p>
              <br />
              <p className={classes.textBlack}>
                {t('IBrainConnectivityAnalysisResultsTitle3')}
              </p>
            </div>

            <Analysis>
              <div className={classes.connectivityBrain}>
                <p className={classes.textBold}>{textPart3Title1}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text1}
                </p>

                <p className={classes.textBold}>{textPart3Title2}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text2}
                </p>

                <p className={classes.textBold}>{textPart3Title3}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text3}
                </p>

                <p className={classes.textBold}>{textPart3Title4}</p>
                <p className={classes.textP}>{textPart3Text4}</p>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default PagePrintWrap3
