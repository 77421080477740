import {RootState} from 'store'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {searchAllApi, SearchAllRequest, SearchAllResponse} from 'api/salesApi'

export const searchSalesOrgUser = createAsyncThunk(
  'api/org/user/list',
  async (payload: SearchAllRequest, {rejectWithValue}) => {
    try {
      const response = await searchAllApi(payload)
      return response.page
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

interface InvoiceUsersOrgState {
  loading: boolean
  error: any
  items: SearchAllResponse[] | null
  search: SearchAllRequest
  paging: PagingV4
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: InvoiceUsersOrgState = {
  loading: false,
  error: null,
  items: [],
  search: {},
  paging: {number: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

export const customerSlice = createSlice({
  name: 'invoiceUsersOrg',
  initialState,
  reducers: {
    resetUserOrgTable: (state) => {
      state.items = []
      state.search = {}
      state.loading = false
      state.error = null
      state.paging.number = 0
      state.pagingInfo.totalPages = 0
      state.pagingInfo.totalElements = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchSalesOrgUser.pending, (state, action) => {
      state.loading = true
      state.search = action.meta.arg
      state.error = null
    })
    builder.addCase(searchSalesOrgUser.fulfilled, (state, action) => {
      state.loading = false
      const {
        size,
        number,
        totalPages,
        totalElements,
        content: items,
      } = action.payload

      state.items = items
      state.loading = false
      state.error = null
      state.paging.number = number
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchSalesOrgUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default customerSlice.reducer
export const {resetUserOrgTable} = customerSlice.actions

export const selectList = (state: RootState) => state.invoiceUsersOrg.items
export const selectLoading = (state: RootState) => state.invoiceUsersOrg.loading
export const selectError = (state: RootState) => state.invoiceUsersOrg.error
export const selectSearch = (state: RootState) => state.invoiceUsersOrg.search
export const selectPaging = (state: RootState) => state.invoiceUsersOrg.paging
export const selectPagingInfo = (state: RootState) =>
  state.invoiceUsersOrg.pagingInfo

export const selectQuery = (state: RootState) => ({
  search: state.invoiceUsersOrg.search,
  paging: state.invoiceUsersOrg.paging,
})
