import {RootState} from 'store'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getLatestNoticeDetailApi, SaleAppNotice} from 'api/salesNoticeApi'
import {
  getPreviousNoticeId,
  removeOffShowNotification,
  setPreviousNoticeId,
} from 'api/storageApi'

export const fetchLatestNotice = createAsyncThunk(
  'fetchLatestNotice',
  async () => {
    const response = await getLatestNoticeDetailApi()
    return response.data
  },
)

interface NotificationState {
  isOpened: boolean
  data: SaleAppNotice | null
  error: any
}

const initialState: NotificationState = {
  isOpened: false,
  data: null,
  error: null,
}

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    open(state) {
      state.isOpened = true
    },
    reject(state) {
      state.isOpened = false
      state.data = null
    },
    close(state) {
      state.isOpened = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLatestNotice.fulfilled, (state, action) => {
      const prevNoticeId = Number(getPreviousNoticeId()) || null
      if (action.payload.noticeId !== prevNoticeId) {
        setPreviousNoticeId(String(action.payload.noticeId))
        removeOffShowNotification()
      }
      state.data = action.payload
      state.error = null
    })
    builder.addCase(fetchLatestNotice.rejected, (state, action) => {
      state.error = action.payload
    })
  },
})

export const {open, reject, close} = slice.actions

export default slice.reducer

export const selectNotification = (state: RootState) => ({
  isOpened: state.notification.isOpened,
  data: state.notification.data,
})
