import request, {normalRequest} from 'api/index'

export const fetchOrgApi = () =>
  request<ModelResponse<Organization>>({
    method: 'get',
    path: '/api/org/v1/detail',
  })

export interface IndustryFetchRequest {
  depth: number
  parent: number
}

export const fetchIndustryListApi = (query: IndustryFetchRequest) =>
  request<ModelsResponse<Industry>>({
    method: 'get',
    path: '/api/org/user/v1/user-industry/list',
    params: query,
  })

export const fetchOrgHistoryApi = (query: UserUsageHistoryRequest) =>
  request<ModelResponse<UserUsageHistory>>({
    method: 'get',
    path: 'api/v1/analysis-usage/org',
    params: query,
  })

export const fetchAccountHistoryApi = (query: UserUsageHistoryRequest) =>
  request<ModelsResponse<UserUsageHistory>>({
    method: 'get',
    path: 'api/v1/analysis-usage/org/user',
    params: query,
  })

export const fetchListAccountByIdApi = (query: AccountByIdRequest) =>
  request<ModelsResponse<AccountById>>({
    method: 'get',
    path: 'api/v1/user',
    params: query,
  })
export const fetchCommonIndustryListApi = (query: IndustryFetchRequest) =>
  normalRequest<ModelsResponse<Industry>>({
    method: 'get',
    path: '/api/org/v1/industry/list',
    params: query,
  })

export const fetchDepartmentListApi = () =>
  request<ModelsResponse<Department>>({
    method: 'get',
    path: '/api/org/v1/department/list',
  })

export interface OrgDepartmentCreateRequest {
  uid: string
  title: string
}

export const createDepartmentApi = (data: OrgDepartmentCreateRequest) =>
  request<ModelResponse<Department>>({
    method: 'post',
    path: '/api/org/v1/department/create',
    data,
  })

export interface OrgDepartmentUpdateRequest {
  uid: string
  title: string
  id: number
}

export const updateDepartmentApi = (data: OrgDepartmentUpdateRequest) =>
  request<ModelResponse<Department>>({
    method: 'put',
    path: `/api/org/v1/department/${data.id}`,
    data,
  })

export const deleteDepartmentApi = (id: number) =>
  request<CommonResponse>({
    method: 'delete',
    path: `/api/org/v1/department/${id}`,
  })

export const deleteDepartmentMultiApi = async (ids: number[]) => {
  const promises = ids.map((id) => deleteDepartmentApi(id))
  const results = await Promise.allSettled(promises)
  const array = results.filter((result) => result.status === 'rejected')
  return array
}

export const fetchOrgDetailIndustryApi = () =>
  request<ModelResponse<Organization>>({
    method: 'get',
    path: '/api/org/v1/detail',
  })

// Preset API
export interface PresetGetRequest {
  uid: string
}

export interface PresetListData {
  createdAt: string
  id: number
  label: string
  orgId: number
  requestTypeList: string[]
  updateAt: string
  zscore: number
}

export interface PresetGetResponse {
  uuid?: string | null
  list: PresetListData[]
  success: boolean
  code: number
  message: string
}

export interface PostPresetData {
  [key: string]: any
}

export interface PresetPostResponse {
  uuid?: string | null
  data: PresetListData[]
  success: boolean
  code: number
  message: string
}

export interface PutPresetData {
  [key: string]: any
  id: number
}

export interface DeletePresetData {
  uid: string
  id: number
}

export interface PresetDeleteResponse {
  success: boolean
  code: number
  message: string
}

export const getPresetApi = (params: PresetGetRequest | DeletePresetData) =>
  request<PresetGetResponse>({
    method: 'get',
    path: '/api/org/v1/analysis/preset',
    params,
  })

export const postPresetApi = (data: PostPresetData) =>
  request<PresetPostResponse>({
    method: 'post',
    path: '/api/org/v1/analysis/preset',
    data,
  })

export const putPresetApi = (data: PutPresetData) =>
  request<PresetPostResponse>({
    method: 'put',
    path: `/api/org/v1/analysis/preset/${data.id}`,
    data,
  })

export const deletePresetApi = (data: DeletePresetData) =>
  request<PresetDeleteResponse>({
    method: 'delete',
    path: `/api/org/v1/analysis/preset/${data.id}`,
    data,
  })

// Register member API

export interface Industry {
  depth?: number
  id: number
  parent?: number
  title: string
}

export interface PostRegisterMember {
  address: string
  customerPo?: string
  area: string
  businessNo: string
  firstUserName: string
  customerFistN?: string
  lastUserName: string
  countryId: number
  detailIndustry: Industry
  edfYn?: 'Y' | 'N'
  email: string
  industry: Industry
  industryDetail: Industry
  privacyYn: 'Y' | 'N'
  policyYn: 'Y' | 'N'
  orgName: string
  institutionNo: string
  orgType?: 'HEALTH' | 'NORMAL'
  packageExpireDate?: string
  packageStartDate?: string
  password: string
  tel: string
  therapyYn?: 'Y' | 'N'
  healthProductIds?: number[]
  twoFactorType?: boolean
  aclu?: boolean
}

export interface RegisterMemberResponse {
  success: boolean
  code: number
  message: string
}

export const postRegisterMemberApi = (data: PostRegisterMember) =>
  request<RegisterMemberResponse>({
    method: 'post',
    path: '/api/manager/v1/org/create',
    data: {...data, twoFactorType: data.twoFactorType ? 'Email' : 'None'},
  })
