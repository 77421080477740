import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'
import useLangEn from 'hooks/useLangEn'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  Frontal_LH: number
  Temporal_LH: number
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>
        {score}
        {t('IPoints')}
      </div>
      <Label score={score} />
    </div>
  )
}

function TablePrintO1({
  Frontal_LH,
  Temporal_LH,
  agingRoiScore,
}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const isEn = useLangEn()
  const headTable = [
    t('IOccipitalLobe'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable6 = useMemo(
    () => [
      {
        data: [
          {
            header: [
              {
                key: 'O1.',
                content: 'Lateral Occipital',
                note: t('ILateralOccipitalLobeO1'),
              },
            ],
            image: getHealthReportReportImage('brainO1.png'),
          },
          [t('IRegionInvolvedO1')],
          isEn
            ? [t('IVisualCognitiveO1'), t('IVisualCognitiveO1V1')]
            : [t('IVisualCognitiveO1')],
          [getNumber(agingRoiScore?.Lateral_Occipital_LH)],
          [getNumber(agingRoiScore?.Lateral_Occipital_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'O2.',
                content: 'Cuneus',
                note: t('ISnowLeafO2'),
              },
            ],
            image: getHealthReportReportImage('brainO2.png'),
          },
          isEn
            ? [t('IIntensityOfLightO2')]
            : [t('IIntensityOfLightO2'), t('IResponsibleForVisualO2')],
          [t('IVisualCognitiveO2'), t('ISevereDamageO2')],
          [getNumber(agingRoiScore?.Cuneus_LH)],
          [getNumber(agingRoiScore?.Cuneus_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'O3.',
                content: 'Pericalcarine',
                note: t('IClawLobeO3'),
              },
            ],
            image: getHealthReportReportImage('brainO3.png'),
          },
          isEn
            ? [t('IResponsibleO3')]
            : [t('IResponsibleO3'), t('IAbleToVisuallyO3')],
          [t('IVisualCognitiveO3'), t('IDifficultyRecognizingO3')],
          [getNumber(agingRoiScore?.Pericalcarine_LH)],
          [getNumber(agingRoiScore?.Pericalcarine_RH)],
        ],
        background: 'White',
      },
      {
        data: [
          {
            header: [
              {
                key: 'O4.',
                content: 'Lingual',
                note: t('ISnowO4'),
              },
            ],
            image: getHealthReportReportImage('brainO4.png'),
          },
          isEn
            ? [t('IRecognizeTheLettersO4')]
            : [
                t('IRecognizeTheLettersO4'),
                t('IResponsibleForConnectingO4'),
                t('IRegionAlsoInvolvedO4'),
              ],
          [t('INoDifficultyO4'), t('IPossibleVisualO4')],
          [getNumber(agingRoiScore?.Lingual_LH)],
          [getNumber(agingRoiScore?.Lingual_RH)],
        ],
        background: 'White',
      },
    ],
    [isEn],
  )

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <WrapElement number={4} text={t('IDetailedAnalysisOccipitalLobe3D')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <span>{t('IOccipitalLobe2')}</span>&nbsp;
                    <span className={classes.headerColorGreen}>
                      {t('IChargeOfVision')}
                    </span>
                    &nbsp;
                    <span>{t('IVisualCenterOfVision')}</span>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IAlsoInvolvedInRecognizing')}</p>
                    <p>{t('IHealthLevelOfEachDetailedArea')}</p>
                    <p>{t('IAssociatedSeizures')}</p>
                    <p>{t('IStandardizedRelative')}</p>
                    <p>{t('ILowerNumberV4')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>좌</div>
                        <Score score={getNumber(Frontal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>우</div>
                        <Score score={getNumber(Temporal_LH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage6.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable6} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintO1
