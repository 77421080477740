import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {createStyles, makeStyles, OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {ClientName} from 'components/atoms/ClientName'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import {useReadDialog} from 'features/therapy/useTherapyDialog'
import useTherapySearch from 'features/therapy/useTherapySearch'
import {dateToDashString} from 'helpers/dateHelper'
import {getQueryParamFromURL} from 'helpers/commonHelper'
import {getOrganizationType} from 'api/storageApi'
import useTransparentGradientStyles from '../Style'

// CYM : 테이블 minWidth Style
export const tableMinWidthStyles = () =>
  createStyles({
    tableMinWidth: {
      '& th': {
        width: 'auto',
      },
      '& th:nth-child(1)': {
        minWidth: 130,
      },
      '& th:nth-child(3)': {
        minWidth: 107,
      },
      '& th:nth-child(4)': {
        minWidth: 60,
      },
      '& th:nth-child(5)': {
        minWidth: 107,
      },
      '& th:nth-child(6)': {
        minWidth: 107,
      },
      '& th:nth-child(7)': {
        minWidth: 95,
      },
      '& th:nth-child(8)': {
        minWidth: 95,
      },
    },
  })
const useTableMinWidthStyles = makeStyles(tableMinWidthStyles)

// for Search
const SEARCH_SELECT_ITEMS: SelectItem<TherapySearchKind>[] = [
  {label: 'ICustomerName', value: 'NAME'},
  {label: 'IChartNo', value: 'CHART_NO'},
  {label: 'IBirthday', value: 'BIRTH'},
  {label: 'IGender', value: 'GENDER'},
  {label: 'IManager', value: 'DOCTOR'},
]

// Table Head
interface HeadCell {
  id: keyof Therapy | 'name' | 'careDoctor'
  label: string
}
const headCells: HeadCell[] = [
  {id: 'chartNo', label: 'IChartNo_no'},
  {id: 'name', label: 'ICustomerName'},
  {id: 'birth', label: 'IBirthday'},
  {id: 'gender', label: 'IGender'},
  {id: 'latestDate', label: 'ILastTherapyDate'},
  {id: 'careDoctor', label: 'IManager'},
  {id: 'reserveYn', label: 'IPrescriptionHistoryNormal'},
  {id: 'isExecuteHealthPbm', label: 'IPBMPrescriptionSet'},
]

const headCellsNormal: HeadCell[] = [
  {id: 'chartNo', label: 'IChartNo_no'},
  {id: 'name', label: 'ICustomerName'},
  {id: 'birth', label: 'IBirthday'},
  {id: 'gender', label: 'IGender'},
  {id: 'latestDate', label: 'ILastTherapyDate'},
  {id: 'careDoctor', label: 'IManager'},
  {id: 'reserveYn', label: 'IPrescriptionHistoryNormal'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props
  const thClasses = useTableMinWidthStyles()
  const organizationType = getOrganizationType()

  return (
    <TableHead>
      <TableRow className={`${classes.tableHeader} ${thClasses.tableMinWidth}`}>
        {(organizationType === 'HEALTH' ? headCells : headCellsNormal).map(
          (headCell) => (
            <TableCell key={headCell.id} align='center' padding='none'>
              {t(headCell.label)}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  uid: string
  uuid: string
}
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {t} = useTranslation()
  const {user: currentUser} = useAuth()
  const classes = useToolbarStyles()
  const {uid, uuid} = props
  const {onFetch, onOpen: onTherapyReadDialogOpen} = useReadDialog()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const outlinedInputClasses = useOutlinedInputStyles()
  const clientName = getQueryParamFromURL(window.location.href, 'clientNamePBM')
  const {query: queryState, onSearch} = useTherapySearch()
  const [searchCondition, setSearchCondition] =
    React.useState<TherapySearchKind>('NAME')
  const [searchKeyword, setSearchKeyword] = React.useState('')

  // CYM : 테라피 처방 예약 버튼 클릭
  const handleReservationClick = () => {
    if (uuid === '') {
      onFailureModalOpen(t('ISelectRequiredItem'))
    } else {
      onFetch(uid, uuid)
      onTherapyReadDialogOpen(uuid)
    }
    return null
  }

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchCondition(event.target.value as TherapySearchKind)
  }

  const handleKeywordSearch = () => {
    const {search, paging: defaultPaging} = queryState
    const {searchName, searchValue} = search
    let searchKeywordCon = searchKeyword
    if (searchCondition === 'NAME' && clientName) {
      searchKeywordCon = searchKeyword || clientName
    }
    if (searchCondition === 'GENDER') {
      const genderKeywordMale = ['남', '남성', '남자', 'man', 'male', 'm']
      const genderKeywordFemale = ['여', '여성', '여자', 'woman', 'female', 'f']

      genderKeywordMale.map((item, i) => {
        if (
          searchKeywordCon.toUpperCase() === genderKeywordMale[i].toUpperCase()
        )
          searchKeywordCon = 'M'
        else if (
          searchKeywordCon.toUpperCase() ===
          genderKeywordFemale[i].toUpperCase()
        )
          searchKeywordCon = 'F'
        return null
      })
    }

    let paging = {...defaultPaging}
    if (searchName !== searchCondition || searchValue !== searchKeyword) {
      // README: 검색 조건, 키워드 변하면 첫 페이지 부터 다시 검색
      paging = {...defaultPaging, page: 0}
    }

    onSearch({
      ...queryState,
      search: {
        searchName: searchCondition,
        searchValue: searchKeywordCon,
      },
      paging,
      uid: currentUser?.uid ?? '',
    })
  }
  const handleValue = (value: string) => {
    setSearchKeyword(value)
  }

  useEffect(() => {
    handleKeywordSearch()
  }, [])

  return (
    <div
      className={classes.root}
      style={{
        justifyContent: 'space-between',
      }}
    >
      <div className={classes.searchContainer}>
        <FormControl variant='outlined'>
          <Select
            value={searchCondition}
            onChange={handleSearchChanged}
            style={{paddingRight: 18}}
            input={
              <OutlinedInput
                name='age'
                id='outlined-age-simple'
                classes={outlinedInputClasses}
              />
            }
          >
            {SEARCH_SELECT_ITEMS.map((item) => (
              <MenuItem value={item.value}>{t(item.label)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchBar
          onChangeValue={handleValue}
          onSearchEvent={handleKeywordSearch}
          disabledValue={false}
        />
      </div>
      <div className={classes.container}>
        <ActionButton onClick={handleReservationClick}>
          {t('ITherapyPrescription')}
        </ActionButton>
      </div>
    </div>
  )
}

interface TherapyTableProps {
  therapyId: string
  setTherapyId: (uuid: string) => void
  setIsTherapySelected: (isChoiced: boolean) => void
  setCustomerName: (customerName: string) => void
}
export default function TherapyCustomerSearchTable(props: TherapyTableProps) {
  const classes = useStyles()
  const gradientClasses = useTransparentGradientStyles()
  const {therapyId, setTherapyId, setIsTherapySelected, setCustomerName} = props
  const {user: currentUser} = useAuth()
  const organizationType = getOrganizationType()
  const {
    therapy: therapyList,
    loading: therapyLoading,
    query: queryState,
    paging: pagingState,
    pagingInfo,
    onSearch,
  } = useTherapySearch()
  const rowsPerPage = 5
  const emptyRows = rowsPerPage - therapyList.length
  const {t} = useTranslation()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const queryUuid = params.get('uuid')

  const setPageIndex = (page: number) => {
    onSearch({
      ...queryState,
      paging: {page, size: queryState.paging.size},
      uid: currentUser?.uid ?? '',
    })
  }

  // CYM : 테라피 Select 연결
  const handleClick = (uuid: string, name: string) => {
    const newSelected = uuid
    setIsTherapySelected(true)
    setTherapyId(newSelected)
    setCustomerName(name)
  }
  const isSelected = (name: string) => therapyId.indexOf(name) !== -1

  const reservationTypeStatus = (row: Therapy) => {
    if (row.reserveYn === 'Y') {
      return t('IPrescriptionY')
    }
    return t('IPrescriptionN')
  }

  const prescriptionStatus = (row: Therapy) => {
    if (row.isExecuteHealthPbm === 'Y') {
      return t('IPBMPrescriptionSet')
    }
    return '-'
  }

  const prescriptionDate = (row: Therapy) => {
    if (organizationType === 'HEALTH') {
      return row.latestDateHealthPbm
        ? dateToDashString(new Date(row.latestDateHealthPbm))
        : '-'
    }
    return row.latestDate ? dateToDashString(new Date(row.latestDate)) : '-'
  }

  useEffect(() => {
    if (queryUuid) {
      setTherapyId(queryUuid)
      setIsTherapySelected(true)
    }
  }, [queryUuid])

  return (
    <TableContainer className={gradientClasses.transparentGradient}>
      <EnhancedTableToolbar uid={currentUser?.uid ?? ''} uuid={therapyId} />
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
          size='small'
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {therapyList.map((row) => {
              const isItemSelected = isSelected(row.uuid)
              return (
                <TableRow
                  hover
                  onClick={() =>
                    handleClick(
                      row.uuid,
                      t('IGetFullName')
                        // .format(row.firstName ?? '', row.lastName ?? '')
                        .trim(),
                    )
                  }
                  role='radio'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.uuid}
                  selected={isItemSelected}
                >
                  <TableCell align='center'>{row.chartNo}</TableCell>
                  <TableCell align='center'>
                    <ClientName
                      firstName={row.firstName}
                      lastName={row.lastName}
                    />
                  </TableCell>
                  <TableCell align='center'>{row.birth}</TableCell>
                  <TableCell align='center'>
                    {row.gender === 'M'
                      ? t('IGenderMaleShort')
                      : t('IGenderFemaleShort')}
                  </TableCell>
                  <TableCell align='center'>{prescriptionDate(row)}</TableCell>
                  <TableCell align='center'>
                    <ClientName
                      firstName={row.docFirstName}
                      lastName={row.docLastName}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    {reservationTypeStatus(row)}
                  </TableCell>
                  {organizationType === 'HEALTH' && (
                    <TableCell align='center'>
                      {prescriptionStatus(row)}
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={pagingState.page}
        itemCountPerPage={pagingState.size}
        setCurrentPageIndex={setPageIndex}
        loading={therapyLoading}
        onItemCountPerPageChanged={(event) => {
          onSearch({
            ...queryState,
            paging: {
              page: 0,
              size: parseInt(event.target.value as string, 10),
            },
            uid: currentUser?.uid ?? '',
          })
        }}
      />
    </TableContainer>
  )
}
