import {CssBaseline} from '@material-ui/core'
import {getLanguage} from 'api/storageApi'
import AppHistory from 'AppHistory'
import {AppLoading} from 'AppLoading'
import AnalysisConfirmDialog from 'components/Dialog/AnalysisConfirmDialog'
import ConfirmDialog from 'components/Dialog/ConfirmDialog'
import FailDialog from 'components/Dialog/FailDialog'
import FindIdDialog from 'components/Dialog/FindIdDialog'
import FindPasswordDialog from 'components/Dialog/FindPasswordDialog'
import OrganizationEditDialog from 'components/Dialog/Organization/Edit'
import OrganizationReadDialog from 'components/Dialog/Organization/Read'
import PatientAddDialog from 'components/Dialog/PatientAddDialog'
import PatientEditDialog from 'components/Dialog/PatientEditDialog'
import PatientReadDialog from 'components/Dialog/PatientReadDialog'
import PointDetailDialog from 'components/Dialog/PointDetailDialog'
import ReferenceDialog from 'components/Dialog/ReferenceDialog'
import SalesUserEditDialog from 'components/Dialog/Sales/User/Edit'
import SalesNoticeDetailDialog from 'components/Dialog/Sales/Notice/Detail'
import SuccessDialog from 'components/Dialog/SuccessDialog'
import UserAddDialog from 'components/Dialog/User/Add'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import UserEditDialog from 'components/Dialog/User/Edit'
import UserReadDialog from 'components/Dialog/User/Read'
import OrgCustomerDialog from 'components/Dialog/CustomerManagement/Organization/index'
import SalesUserReadDialog from 'components/Dialog/Sales/User/Read'
import {ThreeDPopupProvider} from 'context/ThreeDPopupContext'
import {useSilentLogin} from 'features/auth/useAuth'
import {useRefDialog} from 'features/modal/refDialogSlice'
import {init} from 'helpers/firebaseHelper'
import i18n from 'i18n/index'
import ErrorBoundary from 'pages/ErrorPages/ErrorBoundary'
import React, {useEffect} from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Router} from 'react-router-dom'
import Routes from 'routes'
import RouterChangeTracker from 'routes/RouterChangeTracker'
import UserConfirmOpenInvoiceSettingDialog from 'pages/CustomerManagement/InvoiceView/DialogInvoiceView/confirmOpenInvoiceSettingDialog'
import PatientReadWhiteListDialog from 'components/Dialog/PatientReadWhiteListDialog'
import PatientRegistrationWhiteListDialog from 'components/Dialog/PatientRegistrationWhiteListDialog'
import PatientEditWhiteListDialog from 'components/Dialog/PatientEditWhiteListDialog'
import GlobalStyle from './theme/GlobalStyle'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        notifyOnChangeProps: 'tracked',
      },
    },
  })

  const {loading, onSilentLogin} = useSilentLogin()
  const {onOpen: onOpenRefDialog} = useRefDialog()

  const initLang = (lang: string): string => {
    if (lang.includes('ko') || lang.includes('KO')) {
      return 'ko'
    }
    return 'en'
  }

  useEffect(() => {
    const appStarted = async () => {
      init()
      document.documentElement.lang = initLang(navigator.language)
      const lang = getLanguage()
      await i18n.changeLanguage(lang)
      await onSilentLogin()
    }
    appStarted()
  }, [])

  useEffect(() => {
    // @ts-ignore
    // 기존 2.0 레퍼런스 호출하는 내용이다.
    window.fn_callRef = function callRef(referenceId: string) {
      onOpenRefDialog(referenceId)
    }
  }, [])

  RouterChangeTracker()

  return (
    <ErrorBoundary>
      <CssBaseline>
        <QueryClientProvider client={queryClient}>
          <GlobalStyle />
          <ThreeDPopupProvider>
            <AppLoading loading={loading} />
            <Router history={AppHistory}>{!loading && <Routes />}</Router>

            <ConfirmDialog />
            <SuccessDialog />
            <FailDialog />

            <OrganizationReadDialog />
            <OrganizationEditDialog />

            <UserAddDialog />
            <UserEditDialog />
            <UserReadDialog />

            <SalesUserEditDialog />
            <SalesUserReadDialog />
            <SalesNoticeDetailDialog />
            <ReferenceDialog />

            <PointDetailDialog />

            <PatientAddDialog />
            <PatientReadDialog />
            <PatientEditDialog />
            <PatientReadWhiteListDialog />
            <PatientRegistrationWhiteListDialog />
            <PatientEditWhiteListDialog />
            {/* CYM : Therapy Dialog ADD */}

            <FindPasswordDialog />
            <FindIdDialog />

            <AnalysisConfirmDialog />
            <NoticeConfirmDialog />
            <OrgCustomerDialog />
            <UserConfirmOpenInvoiceSettingDialog />
          </ThreeDPopupProvider>
        </QueryClientProvider>
      </CssBaseline>
    </ErrorBoundary>
  )
}

export default App
