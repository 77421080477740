import {Lobe} from 'api/analysisApi'
import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from 'components/Text/ReportSubtitle'

const scoreToString = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score
  if (parsedScore < 16) {
    return 'IBrainAgingScore_underactive'
  }
  if (parsedScore <= 84) {
    return 'IBrainAgingScore_balanced'
  }

  return 'IBrainAgingScore_overactive'
}

const scoreToClass = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 16) {
    return 'Underactive'
  }
  if (parsedScore <= 84) {
    return 'Balanced'
  }

  return 'OverActive'
}

const Score = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const string = scoreToString(score)
  const className = scoreToClass(score)
  return (
    <div style={{textAlign: 'center'}}>
      <div className='Score'>{score}%ile</div>
      <div className={`Label ${className}`}>{t(string)}</div>
    </div>
  )
}

export default function EEGSummaryLobe(props: {lobe: Lobe; index: number}) {
  const {lobe, index} = props
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number={`${index}`}>
        {t('IEEGSummaryReportBrainAgingScore')}
      </ReportSubtitle>

      <div className={classes.p_brainScore}>
        <div className='Brain-score-table'>
          <table>
            <thead>
              <tr>
                <th style={{textAlign: 'left', paddingLeft: 5}}>
                  {t('IBrainAgingScoreTable_area')}
                </th>
                <th style={{textAlign: 'left'}}>
                  {t('IBrainAgingScoreTable_featureP1')}
                </th>
                <th>{t('IBrainAgingScoreTable_left')}</th>
                <th>{t('IBrainAgingScoreTable_right')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='AreaLabel alignMiddle' style={{width: '10vh'}}>
                  {t('IFrontalLobeV2')}
                </td>
                <td className='AreaLabel alignMiddle' style={{width: '35vh'}}>
                  {t('IFrontalLobeFeatureShort')}
                </td>
                <td>
                  <Score score={lobe.Frontal_LH} />
                </td>
                <td>
                  <Score score={lobe.Frontal_RH} />
                </td>
              </tr>
              <tr>
                <td className='AreaLabel alignMiddle'>
                  {t('ITemporalLobeV2')}
                </td>
                <td className='AreaLabel alignMiddle'>
                  {t('ITemporalLobeFeatureShort')}
                </td>
                <td>
                  <Score score={lobe.Temporal_LH} />
                </td>
                <td>
                  <Score score={lobe.Temporal_RH} />
                </td>
              </tr>
              <tr>
                <td className='AreaLabel alignMiddle'>
                  {t('IParietalLobeV2')}
                </td>
                <td className='AreaLabel alignMiddle'>
                  {t('IParietalLobeFeatureShort')}
                </td>
                <td>
                  <Score score={lobe.Parietal_LH} />
                </td>
                <td>
                  <Score score={lobe.Parietal_RH} />
                </td>
              </tr>
              <tr>
                <td className='AreaLabel alignMiddle'>
                  {t('IOccipitalLobeV2')}
                </td>
                <td className='AreaLabel alignMiddle'>
                  {t('IOccipitalLobeFeatureShort')}
                </td>
                <td>
                  <Score score={lobe.Occipital_LH} />
                </td>
                <td>
                  <Score score={lobe.Occipital_RH} />
                </td>
              </tr>
            </tbody>
          </table>

          <span className='Comment'>
            *{t('IEEGSummaryReportBrainAgingScoreComment')}
          </span>
        </div>
        <div className='Brain-score-info'>
          <div className='Title'>
            {t('IEEGSummaryReportBrainAgingScoreTitle')}
          </div>
          <div className='Desc'>
            {t('IEEGSummaryReportBrainAgingScoreDescription')}
          </div>
        </div>
      </div>
    </div>
  )
}
