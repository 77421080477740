import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import {HealthReport} from 'api/healthCareApi'
import Header from 'pages/HealthCenter/Report/Elements/Header'
import HealthCenterMajorIndicators from 'pages/HealthCenter/Report/HealthCenterMajorIndicators'
import {useTranslation} from 'react-i18next'
import BrainFunctionBasic from 'pages/HealthCenter/Report/BrainFunctionBasic'
import {GenderValue, toGenderString} from 'constants/CommonConstant'
import TablePrintF8 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintF8'
import TablePrintF1 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintF1'
import TablePrintT1 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintT1'
import TablePrintT6 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintT6'
import TablePrintP1 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintP1'
import TablePrintO1 from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tablePrintO1'
import PagePrintWrap1 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/pagePrintWrap1'
import PagePrintWrap2 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/pagePrintWrap2'
import PagePrintWrap3 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/pagePrintWrap3'
import AIDementiaPrediction from 'pages/HealthCenter/Report/AIDementiaPrediction'
import HealthCenterHeartRateAnalysisPage from 'pages/HealthCenter/Report/HealthCenterHeartRateAnalysis'
import PrintComponent from 'pages/HealthCenter/Report/Elements/PrintComponent'

const useStyles = makeStyles(HealthCenterReportStyle)

type PropsType = {
  dataHealthReport: HealthReport
  listTab: number[]
  DATA_TITLE: string[][]
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Number(value)
  return 0
}

const WrapPrintPage = ({dataHealthReport, listTab, DATA_TITLE}: PropsType) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [components, setComponents] = useState<React.ReactElement[]>([])

  useEffect(() => {
    const Screen1: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[0][0]}
          content={DATA_TITLE[0][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <HealthCenterMajorIndicators
          fiveFeaturesRadarImage={dataHealthReport.fiveFeaturesRadarImage ?? ''}
          fiveFeaturesScore={dataHealthReport.fiveFeaturesScore}
        />
      </div>
    )
    const Screen2: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[1][0]}
          content={DATA_TITLE[1][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <BrainFunctionBasic
          agingThreeDimensionImage={dataHealthReport.agingThreeDimensionImage}
          agingContourImage={dataHealthReport.agingContourImage}
          agingLobeScore={dataHealthReport.agingLobeScore}
          isPrint
        />
      </div>
    )
    const Screen3: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintF1
          Frontal_LH={getNumber(dataHealthReport.agingLobeScore?.Frontal_LH)}
          Temporal_LH={getNumber(dataHealthReport.agingLobeScore?.Frontal_RH)}
          agingRoiScore={dataHealthReport.agingRoiScore}
        />
      </div>
    )

    const Screen4: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintF8 agingRoiScore={dataHealthReport.agingRoiScore} />
      </div>
    )

    const Screen5: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintT1
          Frontal_LH={getNumber(dataHealthReport.agingLobeScore?.Temporal_LH)}
          Temporal_LH={getNumber(dataHealthReport.agingLobeScore?.Temporal_RH)}
          agingRoiScore={dataHealthReport.agingRoiScore}
        />
      </div>
    )

    const Screen6: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintT6 agingRoiScore={dataHealthReport.agingRoiScore} />
      </div>
    )

    const Screen7: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintP1
          Frontal_LH={getNumber(dataHealthReport.agingLobeScore?.Parietal_LH)}
          Temporal_LH={getNumber(dataHealthReport.agingLobeScore?.Parietal_RH)}
          agingRoiScore={dataHealthReport.agingRoiScore}
        />
      </div>
    )

    const Screen8: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[2][0]}
          content={DATA_TITLE[2][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <TablePrintO1
          Frontal_LH={getNumber(dataHealthReport.agingLobeScore?.Occipital_LH)}
          Temporal_LH={getNumber(dataHealthReport.agingLobeScore?.Occipital_RH)}
          agingRoiScore={dataHealthReport.agingRoiScore}
        />
      </div>
    )

    const Screen9: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[3][0]}
          content={DATA_TITLE[3][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <PagePrintWrap1
          partOneImg={dataHealthReport.brainConnectivityTotalImage}
          brainConnectivityTotalScore={
            dataHealthReport.brainConnectivityTotalScore
          }
          brainConnectivityBetweenLobesScore={
            dataHealthReport.brainConnectivityBetweenLobesScore
          }
          brainConnectivityByLobeScore={
            dataHealthReport.brainConnectivityByLobeScore
          }
        />
      </div>
    )

    const Screen10: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[3][0]}
          content={DATA_TITLE[3][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <PagePrintWrap2
          partTwoImg={dataHealthReport.brainConnectivityBetweenLobesImage}
          partThreeImg={dataHealthReport.brainConnectivityByLobeImage}
          brainConnectivityBetweenLobesScore={
            dataHealthReport.brainConnectivityBetweenLobesScore
          }
          brainConnectivityByLobeScore={
            dataHealthReport.brainConnectivityByLobeScore
          }
        />
      </div>
    )
    const Screen13: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[3][0]}
          content={DATA_TITLE[3][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />

        <PagePrintWrap3
          partTwoImg={dataHealthReport.brainConnectivityBetweenLobesImage}
          partThreeImg={dataHealthReport.brainConnectivityByLobeImage}
          brainConnectivityBetweenLobesScore={
            dataHealthReport.brainConnectivityBetweenLobesScore
          }
          brainConnectivityByLobeScore={
            dataHealthReport.brainConnectivityByLobeScore
          }
        />
      </div>
    )
    const Screen11: React.FC = () => (
      <div id='health-center'>
        <Header
          title={DATA_TITLE[4][0]}
          content={DATA_TITLE[4][1]}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
          sex={t(toGenderString(dataHealthReport.patientGender as GenderValue))}
          birthday={dataHealthReport.patientBirth}
          measurementDate={dataHealthReport.treatmentAt}
        />
        <AIDementiaPrediction
          mciResultImage={dataHealthReport.mciResultImage}
          mciProbability={dataHealthReport.mciProbability}
          name={t('IGetFullName', {
            firstName: dataHealthReport.patientFirstName ?? '',
            lastName: dataHealthReport.patientLastName ?? '',
          })}
        />
      </div>
    )

    const Screen12: React.FC = () => (
      <div id='health-center'>
        {dataHealthReport.hrvId !== null &&
        dataHealthReport.hrvCircularImage !== null &&
        dataHealthReport.hrvTableImage !== null ? (
          <>
            <Header
              title={DATA_TITLE[5][0]}
              content={DATA_TITLE[5][1]}
              name={t('IGetFullName', {
                firstName: dataHealthReport.patientFirstName ?? '',
                lastName: dataHealthReport.patientLastName ?? '',
              })}
              sex={t(
                toGenderString(dataHealthReport.patientGender as GenderValue),
              )}
              birthday={dataHealthReport.patientBirth}
              measurementDate={dataHealthReport.treatmentAt}
            />
            <HealthCenterHeartRateAnalysisPage
              hrvTableImage={dataHealthReport.hrvTableImage}
              hrvCircularImage={dataHealthReport.hrvCircularImage}
              hrvAutoNervousSystemBalance={
                dataHealthReport.hrvAutoNervousSystemBalance
              }
              hrvContourImage={dataHealthReport.hrvContourImage}
              hrvHistogramImage={dataHealthReport.hrvHistogramImage}
              hrvCircularLevel={dataHealthReport.hrvCircularLevel}
              hrvContourLevel={dataHealthReport.hrvContourLevel}
              hrvHistogramLevel={dataHealthReport.hrvHistogramLevel}
            />
          </>
        ) : (
          <>
            <Header
              title={DATA_TITLE[5][0]}
              content={DATA_TITLE[5][1]}
              name={t('IGetFullName', {
                firstName: dataHealthReport.patientFirstName ?? '',
                lastName: dataHealthReport.patientLastName ?? '',
              })}
              sex={t(
                toGenderString(dataHealthReport.patientGender as GenderValue),
              )}
              birthday={dataHealthReport.patientBirth}
              measurementDate={dataHealthReport.treatmentAt}
            />
            <div className={classes.hrvError}>{t('IHrvErrorText')}</div>
          </>
        )}
      </div>
    )

    const dataScreen = [
      [<Screen1 />],
      [<Screen2 />],
      [
        <Screen3 />,
        <Screen4 />,
        <Screen5 />,
        <Screen6 />,
        <Screen7 />,
        <Screen8 />,
      ],
      [<Screen9 />, <Screen10 />, <Screen13 />],
      [<Screen11 />],
      [<Screen12 />],
    ]

    const newComponents: React.ReactElement[] = []

    listTab.forEach((tab) => {
      if (
        !(
          (tab === 0 && dataHealthReport.fiveFeaturesScore === null) ||
          (tab === 1 && dataHealthReport.agingLobeScore === null) ||
          (tab === 2 && dataHealthReport.agingRoiScore === null) ||
          (tab === 3 &&
            dataHealthReport.brainConnectivityByLobeScore === null) ||
          (tab === 4 && dataHealthReport.mciProbability === null) ||
          (tab === 5 && dataHealthReport.hrvId === null)
        )
      ) {
        newComponents.push(...dataScreen[tab])
      }
    })

    setComponents(newComponents)
  }, [dataHealthReport])
  return (
    <div>
      <PrintComponent components={components} />
    </div>
  )
}

export default WrapPrintPage
